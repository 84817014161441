import { React, useState } from 'react';
import { Link } from "react-router-dom";
import Popup from '../components/Popup';

import bench from '../images/labbench.png';
import btnback from '../images/btn-Back.png';
import btnbackglow from '../images/btn-Back-hover.png';
import DrEgg from '../images/DrEgg-cut.png';
import flag from '../images/aboriginal-flag.png';
import flaghover from '../images/aboriginal-flag-glow.png';
import plank from '../images/plank.png';

//Buttons
import weird from '../images/LabButtons/btn_weird_creations2.png';
import facts from '../images/LabButtons/btn_crazy_science2.png';
import fanfic from '../images/LabButtons/btn_kids_stories.png';
import inv from '../images/LabButtons/btn_puzzle.png';
import biogoo from '../images/LabButtons/btn_workshop2.png';

//Buttons Glow
import weirdglow from '../images/LabButtons/btn_weird_creations_glow2.png';
import factsglow from '../images/LabButtons/btn_crazy_science_glow2.png';
import fanficglow from '../images/LabButtons/btn_kids_stories_glow.png';
import invglow from '../images/LabButtons/btn_puzzle_glow.png';
import biogooglow from '../images/LabButtons/btn_workshop_glow2.png';

//Tools
import goggles from '../images/Goggle.png';
import hoverboard from '../images/HoverBoard.png';
import teletube from '../images/Teletube.png';
import wcbox from '../images/weirdcreations-box.png';

import Snake from '../images/characters/Snake.png';
import ClosePop from "../images/close-popup.png";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import useOrientation from '../components/orientation.js';

export const Lab = () => {

  const [buttonPopup, setButtonPopup] = useState(false);
  const isPortrait = useOrientation();
  const [modalState2, setModalState2] = useState(true);
  
  return (
    <div className="Lab-Background-Container">
      <HelmetProvider><Helmet>
        <title>Lab</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <div className="dreggCont">
            <img className="dregg" src={DrEgg} alt="dr egg" />
          </div>

          <div className="button-container">
            <Link to='/Game'><img className="lab-button-inv" src={inv} alt="inventory button" onMouseOver={e => e.currentTarget.src = invglow} onMouseOut={e => e.currentTarget.src = inv} /></Link>
            <Link to='/CrazyFacts'><img className="lab-button-facts" src={facts} alt="crazy facts button" onMouseOver={e => e.currentTarget.src = factsglow} onMouseOut={e => e.currentTarget.src = facts} /></Link>
            <Link to='/WeirdCreations'><img className="lab-button-wc" src={weird} alt="weird creations button" onMouseOver={e => e.currentTarget.src = weirdglow} onMouseOut={e => e.currentTarget.src = weird} /></Link>
            <Link to='/ChooseYourOwnAdventure'><img className="lab-button-fanfic" src={fanfic} alt="chooseyouradventure button" onMouseOver={e => e.currentTarget.src = fanficglow} onMouseOut={e => e.currentTarget.src = fanfic} /></Link>
            <Link to='/WorkshopApps'><img className="lab-button-biogoo" src={biogoo} alt="workshop apps button" onMouseOver={e => e.currentTarget.src = biogooglow} onMouseOut={e => e.currentTarget.src = biogoo} /></Link>
          </div>

          <img className="bench" src={bench} alt="lab bench" />

          <div className="plank-container">
            <img className="plank" src={plank} alt="plank" />
            <p className="plankname">Dr Egg’s Adventure Laboratory</p>
          </div>

          <div className="tool-container">
            <img className="tools" src={goggles} alt="goggles" />
            <img className="tools" src={hoverboard} alt="hoverboard" />
            <img className="tools" src={teletube} alt="teletube" />
          </div>

          <img className="wc-box" src={wcbox} alt="wc box" />
          <Link to='/Map'>
            <img className="Back-Button"
              src={btnback}
              onMouseOver={e => e.currentTarget.src = btnbackglow}
              onMouseOut={e => e.currentTarget.src = btnback}
              alt="home button"
            />
          </Link>

          <img className="aboriginal-flag"
            src={flag}
            onMouseOver={e => e.currentTarget.src = flaghover}
            onMouseOut={e => e.currentTarget.src = flag}
            alt="aboriginal flag"
            onClick={() => setButtonPopup(true)}
          />

          <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
            <div>
              <p className="popup-text">
                Dr Egg Adventures acknowledges the Traditional Custodians of Country throughout Australia and we pay our respects to their Elders, past, present and emerging. We extend that respect to Aboriginal and Torres Strait Islander peoples on this portal today.</p>
            </div>
          </Popup>

          <label htmlFor="chat">
            <div className="StoreSnake" />
          </label>
          <input className="InputStyle" type="checkbox" id="chat" />
          <div className="ChatBoxContainer">
            <label htmlFor="chat">
              <img className="chatClose" src={ClosePop} alt="Close" />
            </label>
            <div className="chatHBox">
              <img className="chatbotImg" src={Snake} alt="Snake" />
            </div>
            <h4 className="chatQBox mt-3"> What do you need help with? </h4>
            <div className="chatABox">
              <input className="InputStyle" type="checkbox" id="Q1" />
              <input className="InputStyle" type="checkbox" id="Q2" />
              <input className="InputStyle" type="checkbox" id="Q3" />
              <input className="InputStyle" type="checkbox" id="Q4" />
              <input className="InputStyle" type="checkbox" id="Q5" />
              <div className="chatCont" id="QCont">
                <label htmlFor="Q1">
                  <div className='chatBoxLabQ1'>
                    <p>🧩 &ensp;What does "PUZZLE GAME" do?</p>
                  </div>
                </label>
                <label htmlFor="Q2">
                  <div className='chatBoxLabQ2'>
                    <p>🧪 &ensp;What does "CRAZY SCIENCE FACTS" do?</p>
                  </div>
                </label>
                <label htmlFor="Q3">
                  <div className='chatBoxLabQ3'>
                    <p>👾 &ensp;What does "WEIRD CREATIONS" do?</p>
                  </div>
                </label>
                <label htmlFor="Q4">
                  <div className='chatBoxQ4'>
                    <p>📖 &ensp;What does "KIDS STORIES" do?</p>
                  </div>
                </label>
                <label htmlFor="Q5">
                  <div className='chatBoxQ5'>
                    <p>⚙️ &ensp;What does "WORKSHOP APPS" do?</p>
                  </div>
                </label>
              </div>
              <div className="chatCont" id="A1">
                <div className='chatBoxLabA1'>
                  <p>🧩 &ensp;What does "PUZZLE GAME" do?</p>
                </div>
                <div className="chatATxt mt-2">
                  <p>↪ Human, do you like puzzles? Sssss... I SURE DO! Click on the “INVENTORY PUZZLE GAME” button and you can download our puzzle game! It will definitely challenge your BRAINSSSSS…</p>
                </div>
                <label htmlFor="Q1">
                  <div className="chatBackBtn" />
                </label>
              </div>
              <div className="chatCont" id="A2">
                <div className='chatBoxLabA1'>
                  <p>🧪 &ensp;What does "CRAZY SCIENCE FACTS" do?</p>
                </div>
                <div className="chatATxt mt-2">
                  <p>↪ FACTS! This button takesssss you to a WORLD of interesting CRAZY facts! Sssss... you humanssss will be sure to find something you don’t know on this page! DON'T BELIEVE ME? I DARE YOU!</p>
                </div>
                <label htmlFor="Q2">
                  <div className="chatBackBtn" />
                </label>
              </div>
              <div className="chatCont" id="A3">
                <div className='chatBoxLabA1'>
                  <p>👾 &ensp;What does "WEIRD CREATIONS" do?</p>
                </div>
                <div className="chatATxt mt-2">
                  <p>↪ WEIRD. ALL THINGS WEIRD. Click on the “WEIRD CREATIONS” button to find anything NOT normal! Ssssss... It will interest you, human! You can even MAKE your own creations and see what other ‘TERRIFYING’ humans can make! If you have had a go at making puzzles of Appsssss, upload them here!</p>
                </div>
                <label htmlFor="Q3">
                  <div className="chatBackBtn" />
                </label>
              </div>
              <div className="chatCont" id="A4">
                <div className='chatBoxLabA1'>
                  <p>📖 &ensp;What does "KIDS STORIES" do?</p>
                </div>
                <div className="chatATxt mt-2">
                  <p>↪ Sssss... do you humans like telling ssssstories as much as I do? Click the “KIDS STORIES” button, and it will take you to a world of adventure stories written by other kids your age! You can even post a story of your own! TRY NOW!</p>
                </div>
                <label htmlFor="Q4">
                  <div className="chatBackBtn" />
                </label>
              </div>
              <div className="chatCont" id="A5">
                <div className='chatBoxLabA1'>
                  <p>⚙️ &ensp;What does "WORKSHOP APPS" do?</p>
                </div>
                <div className="chatATxt mt-2">
                  <p>↪ Hey humanssssss! We have been enjoying making adventure games and stories with you in our workshops. If you want to download some of the Appssss we have been using, click the "WORKSHOP APPS" button! &#40;Lucky they aren’t Asssssps, that’s another kind of sssssnake!&#41; <br></br>Have a go and send us picssss of what you have made via the “WEIRD CREATIONSSSSS” button!</p>
                </div>
                <label htmlFor="Q5">
                  <div className="chatBackBtn" />
                </label>
              </div>
            </div>
          </div>
        </>)}
    </div>
  )
}

export default Lab;