import { useState, React } from 'react';
import Cornelia from '../../images/characters/CorneliaGlow.png';
import Carlin from '../../images/characters/Carlin.png';
import Pigeon from '../../images/characters/Pigeonfixed.png';
import PigeonFlipped from '../../images/characters/Pigeon-Flipped.png';
import RifRaf from '../../images/characters/RifRaf_FrontView.png';
import Back from '../../images/btn-Back.png';
import Blackboard from '../../images/Blackboard.png';
import Popup from '../../components/Popup.js';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../../components/orientation.js';


export const CorneliaScreen = () => {
  // Creating state for popups of companions 
  const [modalState, setModalState] = useState(false);
  const [modalState1, setModalState1] = useState(false);
  const [modalState2, setModalState2] = useState(false);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider><Helmet>
        <title>Cornelia</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={Blackboard} alt="Blackboard" />
          <img className="Individual-Character-Styling" src={Cornelia} alt="Cornelia" />
          <Link to='/Characters'><img className="Back-Button characters" src={Back} alt="Back" /></Link>

          <div className='wc-blackboard-container'>
            <h1 className="Character-Type"> The Kid Inventors </h1>
            <hr className="hr-line"></hr>
            <h4 className="individualCharacter Cornelia-Name display-4 pt-2 pb-4"> Cornelia: THE GOLDEN GIRL </h4>
            <Row>
              <Col md={6} xs={4} className='mx-auto'>
                <div className="Character-Description pt-2">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>🎂 &ensp; Age</Accordion.Header>
                      <Accordion.Body>
                        Cornelia is 13 years old.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>🎭 &ensp; Characteristics</Accordion.Header>
                      <Accordion.Body>
                        Cornelia is a girl with a sunny personality.
                        Animals are attracted to her radiance and other farmers' kids
                        are jealous of her good fortune, because unlike her, most of
                        them are starving. Cornelia is passionate about achieving
                        justice for the animals and to feed the people of Farm World.
                        With a pet mouse as her sidekick, it becomes her mission to
                        unify the animals against Dr Mole and liberate the world’s
                        food and seed supplies from Dr Mole’s control.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>😇 &ensp; Virtues</Accordion.Header>
                      <Accordion.Body>
                        Just. Radiant.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🛠️ &ensp; Tools</Accordion.Header>
                      <Accordion.Body>
                        Pet mouse. Magic corn. Violin.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>⚡️ &ensp; Powers</Accordion.Header>
                      <Accordion.Body>
                        The ability to talk to animals. Inner radiance. Can
                        make anything grow. When she plays the violin, she can put
                        almost any creature into a euphoric trance.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>🗺️ &ensp; World</Accordion.Header>
                      <Accordion.Body>
                        Corn-farmlands.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>

          <img className="Character-Pet CarlinHighlight clicked" src={Carlin} onClick={() => setModalState(true)} alt="Mouse" />
          <img className="Character-Pet-Pigeon PigeonHighlight clicked" src={Pigeon} onClick={() => setModalState1(true)} alt="Pigeon" />
          <img className="Character-Pet-RifRaf RifRafHighlight clicked" src={RifRaf} onClick={() => setModalState2(true)} alt="RifRaf" />

          <Popup trigger={modalState1} setTrigger={setModalState1}>
            <div className='Companion-Text'>
              <h4 className="Pigeon-Name display-5 mt-4 mb-4">  PUFF CHEST  </h4>
              <p className='lead text-center text-dark'>Puff Chest is a Companion Pigeon Air Force Pilot!</p>
              <img className="Character-Pigeon-Popup" src={PigeonFlipped} alt="Pigeon" />

              <div className="Companion-Description-Pigeon shadow-md mx-auto p-3">
                <p>
                  <b>GOAL</b> &ensp;➡️&ensp; Help Cornelia liberate the animals.
                </p>
                <p>
                  <b>SPECIAL POWER</b> &ensp;➡️&ensp; ‘Pigeon-Poop Pummelings’
                </p>
              </div>
            </div>
          </Popup>

          <Popup trigger={modalState} setTrigger={setModalState}>
            <div className='Companion-Text'>
              <h4 className="Carlin-Name display-5 mt-4 mb-4">  CARLIN: CORNELIA'S MOUSE  </h4>
              <p className='lead text-center text-dark'>Carlin is a companion character!</p>
              <img className="Character-Carlin-Popup" src={Carlin} alt="Mouse" />

              <div className="Companion-Description-Carlin shadow-md mx-auto p-3">
                <p>
                  <b>GOAL</b> &ensp;➡️&ensp; Help Cornelia.
                </p>
                <p>
                  <b>SPECIAL POWER</b> &ensp;➡️&ensp; ‘Thought-wave’ clues to Cornelia.
                </p>
              </div>
            </div>
          </Popup>

          <Popup trigger={modalState2} setTrigger={setModalState2}>
            <div className='Companion-Text'>
              <h4 className="RifRaf-Name display-5 mt-4 mb-4">  RIFRAF THE DOG  </h4>
              <p className='lead text-center text-dark'>RifRaf is part of Cornelia’s army!</p>
              <img className="Character-RifRaf-Popup" src={RifRaf} alt="RifRaf" />

              <div className="Companion-Description-RifRaf shadow-md mx-auto p-3">
                <p>
                  <b>GOAL</b> &ensp;➡️&ensp; Help liberate the birds and animals.
                </p>
                <p>
                  <b>SPECIAL POWER</b> &ensp;➡️&ensp; Following a scent, seeing in the dark.
                </p>
              </div>
            </div>
          </Popup>
        </>)}
    </div>
  )
}

export default CorneliaScreen;