import React, { useState } from 'react'
import btnback from '../images/btn-Back.png';
import blackboard from '../images/Blackboard.png';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

export const Trailer = () => {
    const [modalState2, setModalState2] = useState(true);
    const isPortrait = useOrientation();
    return (
        <div className="Lab-Background-Container">
            <HelmetProvider><Helmet>
                <title>Dr Egg Adventures Trailer</title>
            </Helmet></HelmetProvider>
            {isPortrait ? (
                <Popup trigger={modalState2} setTrigger={setModalState2}>
                    <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
                </Popup>
            ) : (
                <>
                    <iframe className="yt-trailer" width="1095" height="572" src="https://www.youtube.com/embed/LfT3ow_3R5Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    <Link to='/Map'><img className="Back-Button" src={btnback} alt="home button" /> </Link>
                    <img className="Blackboard" src={blackboard} alt="blackboard" />
                </>)}
        </div>
    )
}

export default Trailer;