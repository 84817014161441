import WorldConcept from "../../assets/images/WorldConcept.png";
import Ardash from "../../images/characters/Ardash.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import Back from "../../images/btn-Back.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from 'react';
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';

const TechnoWorldSingle = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider>
        <Helmet>
          <title>Techno Towers</title>
        </Helmet>
      </HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={WorldConcept} alt="Techno Towers" />
          <img className="Individual-Character-Styling" style={{ left: '70%', bottom: '0%' }} src={Ardash} alt="Ardash" />

          <Link to="/Worlds">
            <img className="Back-Button characters" src={Back} alt="Back" />
          </Link>

          <div className="wc-blackboard-container">
            <h1 className="Character-Type" style={{ textShadow: '0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1' }}> TECHNO-TOWERS </h1>
            <Row>
              <Col md={6} xs={4} className="mx-auto">
                <div className="Character-Description pt-2">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>📖 &ensp; Story</Accordion.Header>
                      <Accordion.Body>
                        A place of extreme wealth and privilege, the highways of the
                        Techno World are a far cry from the alleys of Slum World.
                        Children from the techno-pods are genetically modified to
                        have special qualities, such glow-in-the-dark bottoms, super
                        eyesight, incredibly increased mathematical abilities or a
                        special gene, which means that they will like eating brussel
                        sprouts. The prosperous residents of Techno World are the
                        consumers of SEEDCORP products. They can eat all the
                        SEEDCORP GM food they like, remaining unaffected by genetic
                        modification of foods due to their superior modified genes.
                        Food shortages are unthought of in Techno World. Not gangs
                        of mutant thugs, but scores of snobby young brat packs rove
                        the malls and department store skyscrapers on their
                        hoverboards, purchasing whatever SEEDCORP is advertising on
                        its ever-present billboards week-to-week.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>🏠 &ensp; Inhabitants</Accordion.Header>
                      <Accordion.Body>
                        Ardash and his mother Prudence and her genetically modified
                        Chihuahua Mufti.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>⚠️ &ensp; Dangers</Accordion.Header>
                      <Accordion.Body>
                        Brat packs of spoiled children.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🌆 &ensp; Landscape</Accordion.Header>
                      <Accordion.Body>
                        High flying highways and super sized skyscrapers.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </>)}
    </div>
  );
};

export default TechnoWorldSingle;