import { React, useState } from 'react';
import ProductOverview from '../images/product-overview.PNG';
import Back from '../images/btn-Back.png';
import Snake from '../images/characters/Snake.png';
import Blackboard from '../images/Blackboard.png';
import pdf from '../pdf/Resource.pdf';
import { Link } from "react-router-dom";
import ClosePop from "../images/close-popup.png";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

const Resources = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();
  return (
    <div className="Resource-Background-Container">
      <HelmetProvider><Helmet>
        <title>Resources</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <Link to='/Map'><img className="Back-Button" src={Back} alt="Back" /></Link>

          <img className="Blackboard" src={Blackboard} alt="Blackboard" />

          <div className='wc-blackboard-container'>
            <h1 className="wc-heading"> Product Overview </h1>
            <hr className="hr-line"></hr>
            <div className="buttons">
              <a href={pdf} download>
                <img className="ProductOverview" src={ProductOverview} alt="Product Overview Button" />
              </a>
              {/* <a href={pdf} target="_blank" download>
              <img className="StudentDownload" src={StudentDownloadButton} alt="Student Download Button"/>
            </a>
            <a href={pdf} target="_blank" download>
              <img className="TeacherDownload" src={TeacherDownloadButton} alt="Teacher Download Button"/>
            </a> */}
            </div>
          </div>

          <label htmlFor="chat">
            <div className="StoreSnake" />
          </label>
          <input className="InputStyle" type="checkbox" id="chat" />
          <div className="ChatBoxContainer">
            <label htmlFor="chat">
              <img className="chatClose" src={ClosePop} alt="Close" />
            </label>
            <div className="chatHBox">
              <img className="chatbotImg" src={Snake} alt="Snake" />
            </div>
            <h4 className="chatQBox mt-3"> What do you need help with? </h4>
            <div className="chatABox">
              <input className="InputStyle" type="checkbox" id="Q1" />
              <div className="chatCont" id="QCont">
                <label htmlFor="Q1">
                  <div className='chatBoxQ1'>
                    <p>What does the button on this page do? 🤔</p>
                  </div>
                </label>
              </div>
              <div className="chatCont" id="A1">
                <div className='chatBoxA1'>
                  <p>What does the button on this page do? 🤔</p>
                </div>
                <div className="chatATxt mt-2">
                  <p>↪ Clicking on this button lets you download EXTRA information about Dr Egg Adventures Laboratory World!</p>
                </div>
                <label htmlFor="Q1">
                  <div className="chatBackBtn" />
                </label>
              </div>
            </div>
          </div>
        </>)}
    </div>
  )
}

export default Resources;