import { React, useState} from 'react';
import { Link } from 'react-router-dom';
import Ardash from '../../images/characters/ArdashGlow.png';
import Back from '../../images/btn-Back.png';
import Blackboard from '../../images/Blackboard.png';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';

export const ArdashScreen = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (

    <div className="Characters-Background-Container">
      <HelmetProvider><Helmet>
        <title>Ardash</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={Blackboard} alt="Blackboard" />
          <img className="Individual-Character-Styling" src={Ardash} alt="Ardash" />
          <Link to='/Characters'><img className="Back-Button characters" src={Back} alt="Back" /></Link>

          <div className='wc-blackboard-container'>
            <h1 className="Character-Type"> The Kid Inventors </h1>
            <hr className="hr-line"></hr>
            <h4 className="individualCharacter Ardash-Name display-4 pt-2 pb-4"> Ardash: THE PLUGGED IN PERFECTIONIST </h4>
            <Row>
              <Col md={6} xs={4} className='mx-auto'>
                <div className="Character-Description pt-2">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>🎂 &ensp; Age</Accordion.Header>
                      <Accordion.Body>
                        Ardash is 13 years old.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>🎭 &ensp; Characteristics</Accordion.Header>
                      <Accordion.Body>
                        Ardash is a boy with no belly button and
                        he is literally perfect. He hails from Techno-world and has
                        been genetically modified so he can’t catch any diseases. He’s
                        a super plugged in and clever kid, and knows heaps about
                        technology. He’s able to work out scientific and mathematical
                        problems super-fast, making him a great helper in Dr Egg’s
                        experiments. He can also travel at super speeds! Ardash likes
                        to investigate and discover new diseases and is searching for
                        one he could catch.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>😇 &ensp; Virtues</Accordion.Header>
                      <Accordion.Body>
                        Fast. Speedy. Can do anything with numbers and data.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🛠️ &ensp; Tools</Accordion.Header>
                      <Accordion.Body>
                        Hover-tube with a built-in compass. Wearable wrist gadget and camera.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>⚡️ &ensp; Powers</Accordion.Header>
                      <Accordion.Body>
                        Genetically modified so he can't catch any diseases. Super eyesight. Super fast. Knows everything about numbers and data!
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>🗺️ &ensp; World</Accordion.Header>
                      <Accordion.Body>
                        Techno-towers.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </>)}
    </div>
  );
}

export default ArdashScreen;