import { useState, React } from 'react'
import { Link } from "react-router-dom";
import '../App.css';
import Enter from '../images/Enter-Button.png';
import DrEggLogo from '../images/Dr-Egg-Logo.png';
import Popup from '../components/Popup';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';


export const Home = () => {

  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Home-Background-Container">
      <HelmetProvider>
        <Helmet>
          <title>Home</title>
        </Helmet>
      </HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img src={DrEggLogo} className="DrEggLogo" alt="Dr Egg Logo" />
          <div className="Home-Button-Wrapper">
            <Link to="/Map">
              <button type="button" className="Home-Enter-Button">
                ENTER
              </button>
            </Link>
          </div>
        </>
      )}
    </div>
  );

}

export default Home;