import WorldConcept from "../../assets/images/WorldConceptSingle.png";
import DrMole from "../../assets/images/DrMole.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import Back from "../../images/btn-Back.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from 'react';
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';

const SeedcorpSingle = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();


  return (
    <div className="Characters-Background-Container">
      <HelmetProvider>
        <Helmet>
          <title>Seedcorp Industrial Lab</title>
        </Helmet>
      </HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img
            className="Blackboard"
            src={WorldConcept}
            alt="Seedcorp Industrial Lab"
          />
          <img
            className="Individual-Character-Styling"
            style={{ left: "0%", bottom: "50%" }}
            src={DrMole}
            alt="Dr Mole"
          />

          <Link to="/Worlds">
            <img className="Back-Button characters" src={Back} alt="Back" />
          </Link>

          <div className="wc-blackboard-container">
            <h1
              className="Character-Type"
              style={{
                textShadow:
                  "0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1",
              }}
            >
              {" "}
              SEEDCORP INDUSTRIAL LAB{" "}
            </h1>

            <Row>
              <Col md={6} xs={4} className="mx-auto">
                <div className="Character-Description pt-2">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>📖 &ensp; Story</Accordion.Header>
                      <Accordion.Body>
                        SEEDCORP industrial laboratories are owned by Dr Mole and
                        form the headquarters of his vast totalitarian empire. The
                        huge complex, located high on a windy hill overlooking the
                        lanes of Slum World, includes Dr Mole's seedhouse and
                        testing laboratories. It is from here that Dr Mole
                        dispatches his army of Hybrid Helpers in their menacing
                        black SEEDCORP vans. Here the insatiable Dr Mole ruthlessly
                        collects every living organic seed on the planet, as well as
                        creating and experimenting on genetically modified and
                        mutant creatures.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>🏠 &ensp; Inhabitants</Accordion.Header>
                      <Accordion.Body>Dr Mole, Hybrid Helpers.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>⚠️ &ensp; Dangers</Accordion.Header>
                      <Accordion.Body>Hybrid Helpers.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🌆 &ensp; Landscape</Accordion.Header>
                      <Accordion.Body>Seedhouse.</Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </>)}
    </div>
  );
};

export default SeedcorpSingle;