import React, { useState } from 'react';
import Blackboard from '../images/Blackboard.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';


export const ErrorPage = () => {
    const [modalState2, setModalState2] = useState(true);
    const isPortrait = useOrientation();

    return (
        <div className="ErrorPageStyle">
            <HelmetProvider><Helmet>
                <title>Error 404</title>
            </Helmet></HelmetProvider>
            {isPortrait ? (
                <Popup trigger={modalState2} setTrigger={setModalState2}>
                    <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
                </Popup>
            ) : (
                <>
                    <link rel="manifest" href="manifest.json" />
                    <div className='ErrorTxtCont'>
                        <div className='ErrorTxt'>
                            <p className='h1ErrorChalkboard'> 404 </p>
                            <p className='h2ErrorChalkboard'>OOPS! PAGE NOT FOUND</p>
                            <p className='h3ErrorChalkboard'>Sorry, the page you’re looking for doesn’t exist. If you think something is broken, report a problem.</p>
                        </div>
                    </div>
                    <img class="ErrorBlackboard" src={Blackboard} alt="Blackboard" />
                    <a href="/">
                        <div className="ErrorHomeButton" alt="Home" />
                    </a>
                    <a href="Contact">
                        <div className="ErrorReportButton" alt="Report" />
                    </a>
                    <div className="ErrorBack" onClick={() => { window.history.back() }} alt="Back" />
                </>)}
        </div>

    )
}

export default ErrorPage
