import React, { useState } from 'react';
import RifRaf from '../../images/characters/RifRaf.png';
import Back from '../../images/btn-Back.png';
import Blackboard from '../../images/Blackboard.png';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';

export const RifRafScreen = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider><Helmet>
        <title>RifRaf</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={Blackboard} alt="Blackboard" />
          <img className="Individual-Character-Styling" src={RifRaf} alt="RifRaf" />

          <Link to='/Characters'><img className="Back-Button characters" src={Back} alt="Back" /></Link>

          <h1 className="Character-Type"> THE COMPANIONS: </h1>
          <h2 className="Individual-Character-Name">  RIF-RAF:  </h2>
          <h2 className="Individual-Character-Slogan">  THE DOG </h2>

          <div className="Companion-Description">
            <p>
              GOAL: Help liberate the birds and animals
            </p>
            <p className="Powers">
              SPECIAL POWER: Following a scent, seeing in the dark
            </p>
          </div>
        </>)}
    </div>
  )
}

export default RifRafScreen;