import styled, { css } from 'styled-components';
import Img from '../assets/images/paper_texture_04.jpg';

const IButton = styled.button`
	font-family: 'quagmire';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 40px;
	width: fill-available;
	margin: 0 auto;
	border: 5px solid #00bfa5;
	border-radius: 25px;
	padding: 5px 50px;
	color: #367771;
	cursor: pointer;
	text-transform: uppercase;
	background-image: url(${Img});
	transform: scale(0.5);

	${props =>
		props.fontSize &&
		css`
			font-size: ${props.fontSize}vh;
		`}

	${props =>
		props.top &&
		css`
			top: ${props.top}%;
		`}

		${props =>
			props.bottom &&
			css`
				bottom: ${props.bottom}%;
			`}

    &:disabled {
		cursor: not-allowed;
	}
`;

const ItchButton = ({ children, fontSize, top, bottom, onClick, disabled }) => {
	return (
		<IButton
			fontSize={fontSize}
			top={top}
			bottom = {bottom}
			onClick={onClick}
			disabled={disabled}>
			{children}
		</IButton>
	);
};

export default ItchButton;
