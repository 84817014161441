import React, { useState } from 'react';
import { Link } from "react-router-dom";
import btnback from '../images/btn-Back.png';
import btnbackglow from '../images/btn-Back-hover.png';
import blackboard from '../images/Blackboard.png';
import Snake from '../images/characters/Snake.png';
import ClosePop from "../images/close-popup.png";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import ThemeButton from '../components/Button';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

const StoryList = ({ stories }) => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();


  return (
    <div className="Lab-Background-Container">
      <HelmetProvider><Helmet>
        <title>Kids Stories</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <Link to='/Lab'>
            <img className="Back-Button" src={btnback} onMouseOver={e => e.currentTarget.src = btnbackglow} onMouseOut={e => e.currentTarget.src = btnback} alt="home button" />
          </Link>

          <div className="wc-blackboard-container">
            <h1 className="wc-heading"> Choose Your Own Adventure </h1>
            <hr className="hr-line"></hr>

            <div className="storyList-container">
              <Row sm={1} lg={2} xxl={3} className='g-0'>
                {stories.map((story) => story.flag === 'Accepted' &&
                  <Col key={story._id} className='my-2 mx-0 px-0'>
                    <Link to={`/ChooseYourOwnAdventure/${story._id}`}>
                      <Card style={{ width: '14rem', height: '8.5rem' }} border='dark' text='center' className='shadow-md mx-auto my-2 storyCard'>
                        <Card.Body>
                          <Card.Title>
                            {story.title}
                          </Card.Title>
                          <Card.Text className='text-dark'>
                            Written by {story.name}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                )}
              </Row>
            </div>

            <Link to={`/SubmitStory`}>
              <Row sm={1} xxl={2}>
                <ThemeButton
                  fontSize={5}
                  bottom={0}>
                  Write your own story!
                </ThemeButton>
              </Row>
            </Link>

          </div>

          <img className="Blackboard" src={blackboard} alt="blackboard" />

          {/* QnA/CHATBOX STARTS HERE. Note to the person who wants to fix this bizarre implementation: try the Offcanvas React-Bootstrap Component instead of this HTML nonsense left behind by the 2021 team. Sincerely, the 2023 team. */}
          <label htmlFor="chat">
            <div className="StoreSnake" />
          </label>
          <input className="InputStyle" type="checkbox" id="chat" />
          <div className="ChatBoxContainer">
            <label htmlFor="chat">
              <img className="chatClose" src={ClosePop} alt="Close" />
            </label>
            <div className="chatHBox">
              <img className="chatbotImg" src={Snake} alt="Snake" />
            </div>
            <h4 className="chatQBox mt-3">What do you need help with?</h4>
            <div className="chatABox">
              <input className="InputStyle" type="checkbox" id="Q1" />
              <input className="InputStyle" type="checkbox" id="Q2" />
              <input className="InputStyle" type="checkbox" id="Q3" />
              <div className="chatCont" id="QCont">
                <label htmlFor="Q1">
                  <div className='chatBoxQ1'>
                    <p>What does clicking a story do? 🤔</p>
                  </div>
                </label>
                <label htmlFor="Q2">
                  <div className='chatBoxQ2'>
                    <p>What does clicking <br /> "WRITE YOUR OWN STORY" do? 🤨</p>
                  </div>
                </label>
                <label htmlFor='Q3'>
                  <div className='chatBoxQ3 mt-4'>
                    <p>Do you want to learn more about interactive stories? 😄</p>
                  </div>
                </label>
              </div>
              <div className="chatCont" id="A1">
                <div className='chatBoxA1'>
                  <p>What does clicking a story do? 🤔</p>
                </div>
                <div className="chatATxt mt-2">
                  <p>
                    ↪ I LOVE reading what other humans have submitted to read.
                    Try clicking any of the stories to read what other humans
                    submitted!
                  </p>
                </div>
                <label htmlFor="Q1">
                  <div className="chatBackBtn" />
                </label>
              </div>
              <div className="chatCont" id="A2">
                <div className='chatBoxA1'>
                  <p>What does clicking <br /> "WRITE YOUR OWN STORY" do? 🤨</p>
                </div>
                <div className="chatATxt mt-4">
                  <p>
                    ↪ Human! Do you like writing? I sure do like reading them!
                    Humans are interesting. Try clicking on the “WRITE YOUR OWN STORY” button to
                    submit your own INTERESTING story for other humans like you to
                    read!
                  </p>
                </div>
                <label htmlFor="Q2">
                  <div className="chatBackBtn" />
                </label>
              </div>
              <div className='chatCont' id='A3'>
                <div className='chatBoxA1'>
                  <p>Do you want to learn more about interactive stories? 😄</p>
                </div>
                <div className='chatATxt mt-4'>
                  <p> ↪ Hey human! Want to learn more about interactive storiessss? You can come along to one of our interactive story workshops at Junior Science Academy in school holidays or look at our workshop page for schoolsssss. Check them out <a href='https://www.mq.edu.au/about/campus-services/children-and-youth/school-holiday-programs/junior-science-academy'>here</a>!</p>
                </div>
                <label htmlFor='Q3'>
                  <div className='chatBackBtn' />
                </label>
              </div>
            </div>
          </div>
        </>)}
    </div>
  )
}

export default StoryList;