import React, { useState } from 'react';
import { Link } from "react-router-dom";
import btnback from '../images/btn-Back.png';
import btnbackglow from '../images/btn-Back-hover.png';
import blackboard from '../images/Blackboard.png';
import icon from '../images/add-icon.png';
import fish from '../images/fish.png';
import iconglow from '../images/icon-glow.png';
import Snake from '../images/characters/Snake.png';
import ClosePop from "../images/close-popup.png";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

const WeirdCreations = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Lab-Background-Container">
      <HelmetProvider><Helmet>
        <title>Wierd Creations</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <Link to='/Lab'><img className="Back-Button" src={btnback} onMouseOver={e => e.currentTarget.src = btnbackglow} onMouseOut={e => e.currentTarget.src = btnback} alt="home button" /></Link>
          <div className="wc-blackboard-container">
            <h1 className="wc-heading"> Weird Creations</h1>
            <hr className="hr-line"></hr>
            <div className="chat-container">
              <div className="chat-view">
                <p className="wc-text">The ability to upload your creations is coming soon!</p>
              </div>
            </div>
          </div>
          <img className="Blackboard" src={blackboard} alt="blackboard" />

          <label htmlFor="chat">
            <div className="StoreSnake" />
          </label>
          <input className="InputStyle" type="checkbox" id="chat" />
          <div className="ChatBoxContainer">
            <label htmlFor="chat">
              <img className="chatClose" src={ClosePop} alt="Close" />
            </label>
            <div className="chatHBox">
              <img className="chatbotImg" src={Snake} alt="Snake" />
            </div>
            <h4 className="chatQBox mt-3"> What do you need help with? </h4>
            <div className="chatABox">
              <input className="InputStyle" type="checkbox" id="Q1" />
              {/* <input className="InputStyle" type="checkbox" id="Q2" /> */}
              <div className="chatCont" id="QCont">
                <label htmlFor="Q1">
                  <div className='chatBoxQ1'>
                    <p>WEIRD CREATIONS?! What is this page? 🧐</p>
                  </div>
                </label>
                {/* <label htmlFor="Q1">
              <div className='chatBoxQ1'>
                <p>WEIRD CREATIONS?! What is this page? 🧐</p>
              </div>
            </label> */}
                {/* <label htmlFor="Q2">
              <div className='chatBoxQ2'>
                <p>What does the button on this page do? 🤔</p>
              </div>
            </label> */}
              </div>
              <div className="chatCont" id="A1">
                <div className='chatBoxA1'>
                  <p>WEIRD CREATIONS?! What is this page? 🧐</p>
                </div>
                <div className="chatATxt mt-2">
                  <p>↪ WEIRD CREATIONS! Human how can you not be excited! Sssss... This page will be ready ssssoon!</p>
                </div>
                <label htmlFor="Q1">
                  <div className="chatBackBtn" />
                </label>
              </div>
              {/* <div className="chatCont" id="A1">
            <div className='chatBoxA1'>
              <p>WEIRD CREATIONS?! What is this page? 🧐</p>
            </div>
            <div className="chatATxt mt-2">
              <p>↪ WEIRD CREATIONS! Human how can you not be excited! Sssss... Find out what your other friends say about their WEIRD CREATIONSssss.</p>
            </div>
            <label htmlFor="Q1">
              <div className="chatBackBtn" />
            </label>
          </div> */}
              {/* <div className="chatCont" id="A2">
            <div className='chatBoxA1'>
              <p>What does the button on this page do? 🤔</p>
            </div>
            <div className="chatATxt mt-2">
              <p>↪ Sssss. This, my friend, lets you make your own post! Something like Twitter, ya kmow? Try clicking on the button to post your own weird creations to your friends! </p>
            </div>
            <label htmlFor="Q2">
              <div className="chatBackBtn" />
            </label>
          </div> */}
            </div>
          </div>
        </>)}
    </div>
  )
}

export default WeirdCreations;