import React, { useState } from 'react';
import { Link } from "react-router-dom";
import btnback from '../images/btn-Back.png';
import btnbackglow from '../images/btn-Back-hover.png';
import blackboard from '../images/Blackboard.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ClosePop from '../images/close-popup.png';
import Snake from '../images/characters/Snake.png';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

const WorkshopApps = () => {
    const [modalState2, setModalState2] = useState(true);
    const isPortrait = useOrientation();
    
    return (
        <div className="Lab-Background-Container">
            <HelmetProvider><Helmet>
                <title>Workshop Apps</title>
            </Helmet></HelmetProvider>
            {isPortrait ? (
                <Popup trigger={modalState2} setTrigger={setModalState2}>
                    <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
                </Popup>
            ) : (
                <>
                    <Link to='/Lab'>
                        <img className="Back-Button" src={btnback} onMouseOver={e => e.currentTarget.src = btnbackglow} onMouseOut={e => e.currentTarget.src = btnback} alt="home button" />
                    </Link>

                    <div className="wc-blackboard-container">
                        <h1 className="wc-heading"> Apps for Dr Egg Workshops </h1>
                        <hr className="hr-line"></hr>
                        <div className="chat-container">
                            <div className="chat-view">
                                <img className="marvel-icon" alt='marvel icon' src='https://is3-ssl.mzstatic.com/image/thumb/Purple126/v4/16/e3/44/16e344fd-2456-10c9-deae-ff9f9b341bb3/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp' />
                                <p className="wa-text">Turn anything into an interactive prototype with <b>Marvel</b>. Get started by downloading the app.
                                    <div className='download-buttons'>
                                        <a href='https://apps.apple.com/au/app/marvel-app/id765801658'>
                                            <img className='wa-apple-button' alt='Download on the App Store' src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1541548800" />
                                        </a>
                                        <a href='https://play.google.com/store/apps/details?id=com.marvelapp'>
                                            <img className='wa-google-button' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                        </a>
                                    </div>
                                </p>
                            </div>

                            <div className="chat-view">
                                <img className="twine-icon" alt='twine icon' src='https://raw.githubusercontent.com/klembot/twinejs/9839c7a19b2f543f41c0c71e7577e8171635ce56/icons/logo.svg' />
                                <p className='wa-text'> <b>TWINE</b> is an open-source tool for telling interactive, nonlinear stories. <br></br>
                                    Get started <a href='https://twinery.org/2/#/'>here</a>.
                                </p>
                            </div>
                        </div>
                    </div>

                    <img className="Blackboard" src={blackboard} alt="blackboard" />

                    {/* QnA/CHATBOX STARTS HERE. Note to the person who wants to fix this bizarre implementation: try the Offcanvas React-Bootstrap Component instead of this HTML nonsense left behind by the 2021 team. Sincerely, the 2023 team. */}
                    <label htmlFor='chat'>
                        <div className='StoreSnake' />
                    </label>
                    <input className='InputStyle' type='checkbox' id='chat' />
                    <div className='ChatBoxContainer'>
                        <label htmlFor='chat'>
                            <img className='chatClose' src={ClosePop} alt='Close' />
                        </label>
                        <div className='chatHBox'>
                            <img className='chatbotImg' src={Snake} alt='Snake' />
                        </div>
                        <h4 className='chatQBox mt-3'>What do you need help with?</h4>
                        <div className='chatABox'>
                            <input className='InputStyle' type='checkbox' id='Q1' />
                            <div className='chatCont' id='QCont'>
                                <label htmlFor='Q1'>
                                    <div className='chatBoxQ1'>
                                        <p>Do you want to learn more about interactive stories? 😄</p>
                                    </div>
                                </label>
                            </div>
                            <div className='chatCont' id='A1'>
                                <div className='chatBoxA1'>
                                    <p>Do you want to learn more about interactive stories? 😄</p>
                                </div>
                                <div className='chatATxt mt-4'>
                                    <p> ↪ Hey human! Want to learn more about interactive storiessss? You can come along to one of our interactive story workshops at Junior Science Academy in school holidays or look at our workshop page for schoolsssss. Check them out <a href='https://www.mq.edu.au/about/campus-services/children-and-youth/school-holiday-programs/junior-science-academy'>here</a>!</p>
                                </div>
                                <label htmlFor='Q1'>
                                    <div className='chatBackBtn' />
                                </label>
                            </div>
                        </div>
                    </div>
                </>)}
        </div>
    )
}

export default WorkshopApps;