import React, {useState} from 'react';
import { Link } from "react-router-dom";
import Ardash from '../images/characters/Ardash.png';
import Cornelia from '../images/characters/Cornelia.png';
import DrEgg from '../images/characters/DrEgg.png';
import DrMoon from '../images/characters/DrMoon.png';
import Vivi from '../images/characters/Vivi.png';
import SnakeImg from '../images/characters/longsnake.png';
import ProfMole from '../images/characters/ProfMole.png';
import Back from '../images/btn-Back.png';
import Snake from '../images/characters/Snake.png';
import ClosePop from "../images/close-popup.png"
import GoodLight from '../images/Good-Light.png';
import BadLight from '../images/Bad-Light.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

const Characters = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Character-Container">
      <HelmetProvider><Helmet>
        <title>Characters</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <div className="Character-Background-Container">
            <div className="Character-Images">
              <figure className="good-characters">

                <img className="good-light" src={GoodLight} alt="Good Light" />
                <img className="bad-light" src={BadLight} alt="Bad Light" />

                <Link to='/Character/DrEgg'><img className="DrEgg all" src={DrEgg} alt="DrEgg" /></Link>
                <Link to='/Character/DrMoon'><img className="DrMoon all" src={DrMoon} alt="DrMoon" /></Link>
                <Link to='/Character/Ardash'><img className="Ardash all" src={Ardash} alt="Ardash" /></Link>
                <Link to='/Character/Vivi'><img className="Vivi all" src={Vivi} alt="Vivi" /></Link>
                <Link to='/Character/Cornelia'><img className="Cornelia all" src={Cornelia} alt="Cornelia" /></Link>

              </figure>

              <figure className="bad-characters">
                <Link to='/Character/ProfessorMole'><img className="ProfMole all " src={ProfMole} alt="ProfMole" /></Link>
              </figure>

              <div className="Character-Bench">
                <Link to='/Character/Snake'><img className="SnakeImg characters" src={SnakeImg} alt="SnakeImg" /></Link>
              </div>
            </div>

            <Link to='/Map'><img className="Back-Button characters" src={Back} alt="Back" /></Link>

            <label htmlFor="chat">
              <div className="StoreSnake" />
            </label>
            <input className="InputStyle" type="checkbox" id="chat" />
            <div className="ChatBoxContainer">
              <label htmlFor="chat">
                <img className="chatClose" src={ClosePop} alt="Close" />
              </label>
              <div className="chatHBox">
                <img className="chatbotImg" src={Snake} alt="Snake" />
              </div>
              <h4 className="chatQBox mt-3"> What do you need help with? </h4>
              <div className="chatABox">
                <input className="InputStyle" type="checkbox" id="Q1" />
                <div className="chatCont" id="QCont">
                  <label htmlFor="Q1">
                    <div className='chatBoxQ1'>
                      <p> Why are all these characters gathered here? </p>
                    </div>
                  </label>
                </div>
                <div className="chatCont" id="A1">
                  <div className='chatBoxA1'>
                    <p> Why are all these characters gathered here? </p>
                  </div>
                  <div className="chatATxt mt-2">
                    <p>↪ See the bright light? That’s the GOOD people! See the dark light? That’s the BAD people! Try clicking on one of them for more INTERESTING INFORMATION!</p>
                  </div>
                  <label htmlFor="Q1">
                    <div className="chatBackBtn" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>)}
    </div>
  )
}

export default Characters;