import React, { useState } from 'react'
import SnakeImg from '../../images/characters/Snake-Highlight2.png';
import Snake2 from '../../images/characters/Snake2.png';
import Back from '../../images/btn-Back.png';
import Blackboard from '../../images/Blackboard.png';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';

export const SnakeScreen = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider><Helmet>
        <title>Snake</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={Blackboard} alt="Blackboard" />
          <img className="Individual-Character-Styling-Snake" src={Snake2} alt="Snake" />
          <Link to='/Characters'><img className="Back-Button " src={Back} alt="Back" /></Link>

          <div className='wc-blackboard-container'>
            <h1 className="Character-Type"> The Narrator </h1>
            <hr className="hr-line"></hr>
            <h4 className="Snake-Name display-4 pt-3 pb-4"> The Snake Narrator </h4>
            <Row>
              <Col md={6} xs={4} className='mx-auto'>
                <div className="Character-Description pt-3">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>🎭 &ensp; Characteristics</Accordion.Header>
                      <Accordion.Body>
                        The Snake narrator moves in
                        and out of the Laboratory’s interactive story-world,
                        and acts as a pop-up guide and shape shifter. In
                        this interactive story-world the witty Snake calls
                        the viewer to action, suggesting how to use the
                        Laboratory and game tools. In the plays and books,
                        the Snake provocatively challenges readers to make
                        ethical decisions and take responsibility for their
                        creations.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>😇 &ensp; Virtues</Accordion.Header>
                      <Accordion.Body>
                        Ethical. Curious. Witty.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>🛠️ &ensp; Tools</Accordion.Header>
                      <Accordion.Body>
                        Flying Egg Spy.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🗺️ &ensp; World</Accordion.Header>
                      <Accordion.Body>
                        Egg Laboratories.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </>)}
    </div>
  )
}

export default SnakeScreen;