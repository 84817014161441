import axios from "axios"

const baseUrl = '/story'

const getAllStory = () => {
    console.log("sevices getAllStory")
    return axios.get('/story')
            .then(response => response.data)
}

const getStory = () => {
    console.log("sevices getStory")
    return axios.get('/story/:id')
            .then(response => response.data)
}

const submitStory = (storyObject, token) => {
    console.log("sevices submitStory")
    return axios.post('/story', {storyObject, token})
            .then(response => response.data)
}

const sendForm = (formObject, token) => {
    console.log("sevices sendForm")
    return axios.post('/send-form', {formObject, token})
            .then(response => response.data)
}

export default {getAllStory, submitStory, getStory, sendForm}