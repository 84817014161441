import React, { useState } from 'react'
import ProfMole from '../../images/characters/ProfMole-Highlight2.png';
import Back from '../../images/btn-Back.png';
import Blackboard from '../../images/Blackboard.png';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';

export const ProfessorMoleScreen = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider><Helmet>
        <title>Professor Mole</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={Blackboard} alt="Blackboard" />
          <img className="Individual-Character-Styling-ProfMole" src={ProfMole} alt="Professor Mole" />
          <Link to='/Characters'><img className="Back-Button characters" src={Back} alt="Back" /></Link>

          <div className='wc-blackboard-container'>
            <h1 className="Character-Type"> The Baddies </h1>
            <hr className="hr-line"></hr>
            <h4 className="individualCharacter Mole-Name display-4 pt-3 pb-4"> Professor Mole: The Greedy Controller </h4>
            <Row>
              <Col md={6} xs={4} className='mx-auto'>
                <div className="Character-Description pt-3">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>🎭 &ensp; Characteristics</Accordion.Header>
                      <Accordion.Body>
                        Professor Mole controls the world’s food supply
                        from his vast totalitarian empire, SEEDCORP. His
                        goal is to control all the seed in the world. Once
                        a friend and colleague of Dr Egg and Dr Moon,
                        his lust for fame and power overtook him, and he
                        stole their breakthrough invention, the Square
                        Tomato. Living on a diet of GM seeds, he has
                        mutated into a part-mole, part-human creature
                        emitting strange animal odours.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>😇 &ensp; Virtues</Accordion.Header>
                      <Accordion.Body>
                        None.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>🛠️ &ensp; Tools</Accordion.Header>
                      <Accordion.Body>
                        Hybrid Helpers. GM Seeds.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🗺️ &ensp; World</Accordion.Header>
                      <Accordion.Body>
                        SEEDCORP Empire.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </>)}
    </div>
  )
}

export default ProfessorMoleScreen;