import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Largescreen.css';
import './Mobile.css';
import './external.css';

import { Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from "react";

import storyService from "./services/submission.js";
import storyServices from "./services/submission";

// main screens
import Home from './screens/Home.js';
import Map from './screens/Map.js';
import Game from './screens/Game.js';
import Contact from './screens/Contact.js';
import Characters from './screens/Characters.js';
import Lab from './screens/Lab.js';
import OnlineStore from './screens/OnlineStore.js';
import Trailer from './screens/Trailer.js';
import Resources from './screens/Resources.js';
import Worlds from './screens/Worlds.js';

// forms
import ContactForm from './screens/ContactForm';
import SubmitStory from './screens/SubmitStory';

// single world screens
import SlumWorldSingle from './screens/worlds/SlumWorldSingle';
import TechnoWorldSingle from './screens/worlds/TechnoWorldSingle';
import FarmWorldSingle from './screens/worlds/FarmWorldSingle';
import LabWorldSingle from './screens/worlds/LabWorldSingle';
import SeedcorpSingle from './screens/worlds/SeedcorpSingle';

// lab screens 
import WeirdCreations from './screens/WeirdCreations.js';
import CrazyFacts from './screens/CrazyFacts';
import WorkshopApps from './screens/WorkshopApps.js';
import Fanfic2 from './screens/Fanfic2';
import StoryList from './screens/StoryList';

import ErrorPage from './screens/ErrorPage';

// individual character screens
import Ardash from './screens/characters/ArdashScreen.js';
import DrEgg from './screens/characters/DrEggScreen.js';
import Vivi from './screens/characters/ViviScreen.js';
import DrMoon from './screens/characters/DrMoonScreen.js';
import Cornelia from './screens/characters/CorneliaScreen.js';
import RifRaf from './screens/characters/RifRafScreen.js';
import Snake from './screens/characters/SnakeScreen.js';
import ProfMole from './screens/characters/ProfessorMoleScreen.js';

import storyData from './assets/story/story.json'

const App = () => {
	const [stories, setStories] = useState(storyData.stories) // Initialize state with storyData

	const addStory = (storyObject, token) => {
		console.log("app.js addStory")
		storyServices.submitStory(storyObject, token)
			.then(object => {
				console.log("POST response", object)
				setStories(stories.concat(object))
				console.log("new story added", object)
			})
	}

	const sendForm = (formObject, token) => {
		console.log("app.js addContact")
		storyServices.sendForm(formObject, token)
			.then(object => {
				console.log("POST response", object)
				console.log("new contact run", object)
			})
	}


	useEffect(() => {
		console.log("effect is being run")
		storyServices.getAllStory()
			.then(object => {
				console.log("we have a response", object)
				setStories(object)
			})
	}, [])

	// setting the structure of the portal
	return (
		<Routes>
			<Route exact path='/' element={<Home />} />
			<Route path='/Map' element={<Map />} />
			<Route path='/Game' element={<Game />} />
			<Route path='/Lab' element={<Lab />} />
			<Route path='/OnlineStore' element={<OnlineStore />} />
			<Route path='/Trailer' element={<Trailer />} />
			<Route path='/Resources' element={<Resources />} />
			<Route path='/Contact' element={<Contact />} />
			<Route path='/ContactForm' element={<ContactForm sendForm={sendForm} />} />

			<Route path='/Worlds' element={<Worlds />} />
			<Route path='/Worlds/CornFarmlands' element={<FarmWorldSingle />} />
			<Route path='/Worlds/SlumLanes' element={<SlumWorldSingle />} />
			<Route path='/Worlds/TechnoTowers' element={<TechnoWorldSingle />} />
			<Route path='/Worlds/SeedcorpLab' element={<SeedcorpSingle />} />
			<Route path='/Worlds/EggLab' element={<LabWorldSingle />} />

			<Route path='/Characters' element={<Characters />} />
			<Route path='/Character/Ardash' element={<Ardash />} />
			<Route path='/Character/DrEgg' element={<DrEgg />} />
			<Route path='/Character/Vivi' element={<Vivi />} />
			<Route path='/Character/DrMoon' element={<DrMoon />} />
			<Route path='/Character/Cornelia' element={<Cornelia />} />
			<Route path='/Character/Snake' element={<Snake />} />
			<Route path='/Character/ProfessorMole' element={<ProfMole />} />
			<Route path='/Character/RifRaf' element={<RifRaf />} />

			<Route path='/WeirdCreations' element={<WeirdCreations />} />
			<Route path='/CrazyFacts' element={<CrazyFacts />} />
			{/* <Route path='/ChooseYourAdventure' element={<Fanfic stories={stories}  />} /> */}
			<Route path='/ChooseYourOwnAdventure/:id' element={<Fanfic2 stories={stories}  />} />
			<Route path='/ChooseYourOwnAdventure' element={<StoryList stories={stories}  />} />
			<Route path='/WorkshopApps' element={<WorkshopApps />} />
			<Route path='/SubmitStory' element={<SubmitStory addStory={addStory} />} />

			<Route element={ErrorPage} />
		</Routes>
	);
}

export default App;