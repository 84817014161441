import { React, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import Back from '../images/btn-Back.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Snake from '../images/characters/Snake.png';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';
import Row from 'react-bootstrap/Row';
import ItchButton from '../components/ItchButton.js';

const Fanfic2 = ({ stories }) => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();
  let { p1, p2, p3, p4, p5 } = []

  const id = useParams().id
  const story = stories.find(s => s._id === id)

  console.log('Getting story with id: ', id)

  // now need to slice up the story and spread across pages
  function splitString(str) {
    if (str.length < 1666) {
      p1 = str
      return str;
    }

    const words = str.split(" ");
    const numWords = words.length;
    const wordLength = Math.ceil(numWords / 5);

    const firstPart = words.slice(0, wordLength).join(" ");
    const secondPart = words.slice(wordLength, wordLength * 2).join(" ");
    const thirdPart = words.slice(wordLength * 2, wordLength * 3).join(" ");
    const fourthPart = words.slice(wordLength * 3, wordLength * 4).join(" ");
    const fifthPart = words.slice(wordLength * 4).join(" ");

    p1 = firstPart
    p2 = secondPart
    p3 = thirdPart
    p4 = fourthPart
    p5 = fifthPart

    return [firstPart, secondPart, thirdPart, fourthPart, fifthPart];
  }

  if (story && splitString(story.message))
    return (
      <div className="OnlineStore-Background-Container">

        <HelmetProvider><Helmet>
          <title>Story: {story.title}</title>
        </Helmet></HelmetProvider>
        {isPortrait ? (
          <Popup trigger={modalState2} setTrigger={setModalState2}>
            <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
          </Popup>
        ) : (
          <>
            <div className="OnlineStore-Container">
              <input className="InputStyle" type="checkbox" id="c1" />
              <input className="InputStyle" type="checkbox" id="c2" />
              <input className="InputStyle" type="checkbox" id="c3" />

              <div id="bookCover">
                <div className="imgPageCoverContainer">
                  <div className='my-auto'>
                    <img className="snakeBook mb-4" src={Snake} alt="Snake" />
                    <p className='bookInfo'>
                      You are about to read the story titled <i>{story.title}</i>,<br />
                      written by <b>{story.name}</b>!
                    </p>
                  </div>

                </div>
              </div>

              <div className="pageContainer">
                <div className="page" id="p1">
                  <div className="backPageContainer">
                    <div className="backPage">
                      <div className='bookTxt2 py-4 px-5'>
                        <p className='book-text'>
                          {p2}
                        </p>
                      </div>
                    </div>
                    <label htmlFor="c1"><div className="backBtn" /></label>
                  </div>
                  <div className="frontPageContainer">
                    <div className="frontPage">
                      <div className="bookTxt py-4 px-5">
                        <h3 className="book-heading">{story.title}</h3>
                        <p className="book-text">
                          {p1}
                        </p>
                      </div>
                    </div>
                    <Link to={story.itch}>
                      <Row sm={1} xxl={2}>
                        <ItchButton
                          fontSize={5}
                          bottom={0}>
                          Continue <span style={{color: '#DE4730'}}> {story.title} </span> story with interactive choices here!
                        </ItchButton>
                      </Row>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Link to='/ChooseYourOwnAdventure'>
              <img
                className='Back-Button'
                src={Back}
                alt='Back'
              />
            </Link>
          </>)}
      </div>
    )
}

export default Fanfic2;
