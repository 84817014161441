import { React, useState } from 'react';
import DrMoon from '../../images/characters/DrMoonGlow.png';
import Back from '../../images/btn-Back.png';
import Blackboard from '../../images/Blackboard.png';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';

export const DrMoonScreen = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider><Helmet>
        <title>Dr Moon</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={Blackboard} alt="Blackboard" />
          <img className="Individual-Character-Styling-Scientist" src={DrMoon} alt="Dr Moon" />
          <Link to='/Characters'><img className="Back-Button characters" src={Back} alt="Back" /></Link>

          <div className='wc-blackboard-container'>
            <h1 className="Character-Type"> The Scientists </h1>
            <hr className="hr-line"></hr>
            <h4 className="individualCharacter DrEgg-Name display-4 pt-3 pb-4"> Dr Cecilia Moon: THE DNA KNITTER </h4>
            <Row>
              <Col md={6} xs={4} className='mx-auto'>
                <div className="Character-Description pt-3">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>🎭 &ensp; Characteristics</Accordion.Header>
                      <Accordion.Body>
                        The twin sister of Dr Egg. She
                        graduated University at the same time as the
                        Doctor and also Professor Magnus Mole. She has
                        worked alongside Dr Egg at EGG LABORATORIES
                        creating giant corn, square tomatoes and
                        incubating all sorts of eggs and inventions. She
                        dresses in a white laboratory coat, its pockets
                        stuffed with laboratory implements and balls of
                        wool. She keeps her bun on her head with knitting
                        needles which protrude in all directions.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>😇 &ensp; Virtues</Accordion.Header>
                      <Accordion.Body>
                        Industrious. Inventive. Unflappable.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>🛠️ &ensp; Tools</Accordion.Header>
                      <Accordion.Body>
                        Knitting needles. Petri dishes. Bicycle generator.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🗺️ &ensp; World</Accordion.Header>
                      <Accordion.Body>
                        Egg Laboratories.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </>)}
    </div>
  )
}

export default DrMoonScreen;