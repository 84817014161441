import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '../components/Popup';
import ardash from '../images/ardash-face.png';
import vivi from '../images/vivi-face.png';
import close from '../images/close-popup.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';

const Game = () => {
    const navigate = useNavigate()
    const [buttonPopup1, setButtonPopup1] = useState(true);
    const [modalState2, setModalState2] = useState(true);
    const isPortrait = useOrientation();

    return (
        <div className="Game-Background-Container">
            <HelmetProvider><Helmet>
                <title>Game</title>
            </Helmet></HelmetProvider>
            {isPortrait ? (
                <Popup trigger={modalState2} setTrigger={setModalState2}>
                    <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
                </Popup>
            ) : (
                <>
                    <Popup trigger={buttonPopup1} setTrigger={setButtonPopup1} getbk={false}>
                        <h3 className="game-text1">
                            The Dr Egg Adventures Interactive <br></br>Book and Puzzle Game
                        </h3>

                        <div className='game-download-buttons'>
                            <a href="https://apps.apple.com/au/app/dr-egg-adventures-laboratory/id1372550003">
                                <img className="game-apple-button" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1541548800" alt="apple" />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.AdventureLabs.InteractiveBook&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <img className="game-google-button" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                            </a>
                        </div>

                        <p className="game-text2">Click to download the app now!</p>

                        <img className="ardash-face" src={ardash} alt="ardash" />
                        <img className="vivi-face" src={vivi} alt="vivi" />

                        <img className="close-btn" src={close} alt="close" onClick={() => navigate(-1)} />
                    </Popup>
                </>)}
        </div>
    )
}

export default Game;