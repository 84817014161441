import { useState, React } from 'react';
import { Link } from 'react-router-dom';
import Snake from '../images/characters/Snake.png';
import ClosePop from "../images/close-popup.png"
import farmworld from '../images/farmworld.png';
import slumworld from '../images/slumworld.png';
import technoworld from '../images/technoworld.png';
import egglab from '../images/egglab.png';
import seedcorp from '../images/seedcorp.png';
import Popup from '../components/SnakePopup.js';
import Back from '../images/btn-Back.png';
import card from '../images/Chatbot/ChatboxCard.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';

const Worlds = () => {

  const [modalState, setModalState] = useState(true);
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();
  return (
    <div className="Background-Container">
                      <HelmetProvider><Helmet>
                    <title>Worlds</title>
                </Helmet></HelmetProvider>
                {isPortrait ? (
                <Popup trigger={modalState2} setTrigger={setModalState2}>
                    <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
                </Popup>
            ) : (
                <>
      <div className="worldContainer">
        <div className="farmworld1" id="FWorld">
          <Link to="/Worlds/CornFarmlands">
            <img className="world" src={farmworld} alt="Corn-Farmlands" />
          </Link>
        </div>
        <div className="slumworld1" id="SWorld">
          <Link to="/Worlds/SlumLanes">
            <img className="world" src={slumworld} alt="Slum-Lanes" />
          </Link>
        </div>
        <div className="seedcorp1" id="SedWorld">
          <Link to="/Worlds/SeedcorpLab">
            <img className="world" src={seedcorp} alt="Seedcorp Lab" />
          </Link>
        </div>
        <div className="egglab1" id="LWorld">
          <Link to="/Worlds/EggLab">
            <img className="world" src={egglab} alt="Dr Egg Lab" />
          </Link>
        </div>
        <div className="technoworld1" id="TechWorld">
          <Link to="/Worlds/TechnoTowers">
            <img className="world" src={technoworld} alt="Techno-Towers" />
          </Link>
        </div>
        <div className="egglab1" id="LWorld2">
          <Link to="/Worlds/EggLab">
            <img className="world" src={egglab} alt="Dr Egg Lab" />
          </Link>
        </div>
      </div>

      <Link to="/Map">
        <img className="Back-Button characters" src={Back} alt="Back" />
      </Link>


      <label htmlFor="chat">
        <div className="StoreSnake" />
      </label>
      <input className="InputStyle" type="checkbox" id="chat" />
      <div className="ChatBoxContainer">
        <label htmlFor="chat">
          <img className="chatClose" src={ClosePop} alt="Close" />
        </label>
        <div className="chatHBox">
          <img className="chatbotImg" src={Snake} alt="Snake" />
        </div>
        <h4 className="chatQBox mt-3"> What do you need help with? </h4>
        <div className="chatABox">
          <input className="InputStyle" type="checkbox" id="Q1" />
          <div className="chatCont" id="QCont">
            <label htmlFor='Q1'>
              <div className='chatBoxQ1'>
                <p>What am I supposed to do on this page? 🤨</p>
              </div>
            </label>
          </div>
          <div className="chatCont" id="A1">
            <div className='chatBoxA1'>
              <p>What am I supposed to do on this page? 🤨</p>
            </div>
            <div className="chatATxt mt-2">
              <p>↪ Sssss. Click on one of the WORLD CARDS to discover interesting factsssss about each world that our INTERESTING charactersssss reside in! Try clicking on one now!</p>
              <img src={card} alt="World Card" />
            </div>
            <label htmlFor="Q1">
              <div className="chatBackBtn" />
            </label>
          </div>
        </div>
      </div>
      </>)}
    </div>
  )
}

export default Worlds;