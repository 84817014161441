import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import Back from "../images/btn-Back.png";
import Blackboard from "../images/Blackboard.png";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/css/modal.css";
import "../assets/css/spinner.css";
import LoadScreen from "../components/LoadScreen";
import styled from "styled-components";
import ThemeButton from "../components/Button";
import CorneliaFace from "../assets/images/CorneliaFace.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "../axios";
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

const ErrorMsg = styled.span`
  display: block;
  color: red;
  margin-top: 5px;
`;

const ContactTitle = styled.h1`
  color: #fff;
  font-family: "polaris", sans-serif;
  font-size: 4.5vh;
  text-align: center;
  font-weight: 700;
  word-spacing: 0.25em;
  padding: 2px;
  margin-top: 1em;
`;

export const SubmitStory = ({ addStory }) => {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  //char count for wordlimit
  const maxWords = 1000;

  const [wordCount, setWordCount] = useState(0);
  const [text, setText] = useState("");
  const [charCount, setCharCount] = useState(0);

  const [form, setForm] = useState({
    name: "",
    // email: "",
    title: "",
    message: "",
    flag: "Pending",
  });
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState({
    status: 0,
    msg: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    // email: "",
    title: "",
    message: "",
    flag: "Pending",
  });

  const changeHandler = (event) => {
    setText(event.target.value);
  };

  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  useEffect(() => {
    // array of words
    const words = form["message"].split(/\s+/);

    // update word count
    let wordCount = 0;
    words.forEach((word) => {
      if (word.trim() !== "") {
        wordCount++;
      }
    });
    setWordCount(wordCount);
    // update char count (including whitespaces)
    setCharCount(text.length);
  }, [form["message"]]);

  const toggleModal = () => setModal(!modal);

  const validateForm = (field, form) => {
    const errors = {};
    // errors.name='ok'
    // errors.email = 'ok'
    // errors.title = 'ok'
    // errors.message = 'ok'

    if (!form.name && field === "name") {
      errors.name = "invalid";
    }

    if (!form.title && field === "title") {
      errors.title = "invalid";
    }

    if (field === "message") {
      if (!form.message) {
        errors.message = "invalid";
      }
      //TODO: make error appear if charcount is greater that 5000
      if (wordCount >= maxWords) {
        errors.message = "invalid";
      }
    }
    setErrors(errors);
    console.log(errors);
    return errors;
  };

  const handleFieldChange = (e, name) => {
    const newValue = {
      ...form,
      [name]: e.target.value,
    };
    setForm(newValue);

    console.log("form", form);
    validateForm(name, newValue);
  };

  const [error, setError] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    fetch("https://formcarry.com/s/OTjSjizyvP", {
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name: form.name, email: form.title, message: form.message })
    })
      .then(response => response.json())
      .then(response => {
        if (response.code === 200) {
          alert("We received your submission, thank you!");
        }
        else if (response.code === 422) {
          // Field validation failed
          setError(response.message)
        }
        else {
          // other error from formcarry
          setError(response.message)
        }
      })
      .catch(error => {
        // request related error.
        setError(error.message ? error.message : error);
      });
  }

  return (
    <>
      <div className="Contact-Background-Container">
        <img className="Blackboard" src={Blackboard} alt="Blackboard" />
        <img
          className="Back-Button characters"
          src={Back}
          alt="Back"
          onClick={() => navigate(-1)}
        />

        <HelmetProvider>
          <Helmet>
            <title>Submit Story</title>
          </Helmet>
        </HelmetProvider>
        {isPortrait ? (
          <Popup trigger={modalState2} setTrigger={setModalState2}>
            <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
          </Popup>
        ) : (
          <>
            <div className="Contact-Container">
              <ContactTitle>Submit your own story!</ContactTitle>
              <Form className="Contact-Form" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} style={{ paddingLeft: "0px" }}>
                    <div className="Contact-FormGroup">
                      <FormGroup floating>
                        <Input
                          id="fname"
                          type="FullName"
                          placeholder="Full Name"
                          className="setFormBg"
                          invalid={errors.name === "invalid"}
                          style={{ fontFamily: "museo sans" }}
                          required
                          value={form["name"]}
                          onChange={(e) => handleFieldChange(e, "name")}
                        />
                        <FormFeedback tooltip>Please enter your name.</FormFeedback>
                        <Label for="fname" style={{ fontFamily: "museo sans" }}>
                          Your Name
                        </Label>
                      </FormGroup>{" "}
                    </div>
                  </Col>
                  <Col md={6} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    {/* <div className="Contact-FormGroup">
                    <FormGroup floating>
                      <div className="Contact-Label">
                        <Label className="MLabel" for="mail"></Label>
                      </div>
                      <div className="input-wrapper">
                        <Input
                          type="email"
                          name="email"
                          id="mail"
                          placeholder="Email Address"
                          invalid={errors.email === "invalid"}
                          required
                          value={form["email"]}
                          onChange={(e) => handleFieldChange(e, "email")}
                        />
                        <FormFeedback tooltip>
                          Email incorrect or missing.
                        </FormFeedback>
                      </div>
                    </FormGroup>{" "}
                  </div> */}
                    <div className="Contact-FormGroup">
                      <FormGroup floating>
                        {/* <div className='Contact-Label'> */}

                        {/* </div> */}
                        {/* <div className="input-wrapper"> */}
                        <Input
                          id="titles"
                          type="Title"
                          placeholder="Story Title"
                          className="setFormBg"
                          style={{ fontFamily: "museo sans" }}
                          required
                          invalid={errors.title === "invalid"}
                          value={form["title"]}
                          onChange={(e) => handleFieldChange(e, "title")}
                        />
                        <FormFeedback tooltip>Please enter a title.</FormFeedback>
                        {/* </div> */}
                        <Label for="titles" style={{ fontFamily: "museo sans" }}>
                          Story Title
                        </Label>
                      </FormGroup>{" "}
                    </div>
                  </Col>
                </Row>

                {/* <Row>
                <div className="Contact-FormGroup">
                  <FormGroup floating>
                    <Input
                      id="titles"
                      type="Title"
                      placeholder="Story Title"
                      required
                      invalid={errors.title === "invalid"}
                      value={form["title"]}
                      onChange={(e) => handleFieldChange(e, "title")}
                    />
                    <FormFeedback tooltip>Please enter a title.</FormFeedback>
                    <Label for="titles">Story Title</Label>
                  </FormGroup>{" "}
                </div>
              </Row> */}

                <Row>
                  <div className="Contact-FormGroup">
                    <FormGroup>
                      {/* potentially change it back to characters? rn it is  */}
                      {/* <Label className="MLabel">Message: {Math.round(count/5)}/{Math.round(maxChars/5)}</Label>  */}
                      <Label style={{ fontFamily: "museo sans", color: "#fff", fontSize: "2vh" }}>
                        Story word count: {wordCount}/{maxWords}
                      </Label>
                      <Input
                        className="Contact-MessageBox setFormBg"
                        type="textarea"
                        name="text"
                        id="message"
                        style={{ fontFamily: "museo sans" }}
                        //maxLength={wordsLeft} //in chars, with avg word length of 5 chars -> 5000 chars = 1000 words
                        required
                        valid={errors.message === "ok"}
                        invalid={errors.message === "invalid"}
                        value={form["message"]}
                        onChange={(e) => {
                          handleFieldChange(e, "message");
                          changeHandler(e);
                        }}
                      />
                      <FormFeedback tooltip>
                        Story field empty or over word limit.
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </Row>

                <Row>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={captchaRef}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transform: "scale(0.77)",
                    }}
                  />
                </Row>

                <Row>
                  <FormGroup check inline className="py-2 w-75 mx-auto">
                    <Input
                      style={{ backgroundImage: "none !important" }}
                      type="checkbox"
                      required
                    />
                    <Label
                      style={{ fontFamily: "museo sans", color: "#ffffff", fontSize: "0.9em" }}
                      check
                    >
                      I agree for my story to be shared. For more information view
                      our{" "}
                      <a
                        href="https://dreggadventures.com/privacy-policy/"
                        target="_blank"
                        style={{ color: "#F78F96" }} rel="noreferrer"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://dreggadventures.com/terms-of-service/"
                        target="_blank"
                        style={{ color: "#F78F96" }} rel="noreferrer"
                      >
                        Terms of Service
                      </a>
                      .
                    </Label>
                  </FormGroup>
                </Row>

                <Row md={3}>
                  <ThemeButton
                    fontSize={7}
                    bottom={0.5}
                    disabled={Object.keys(errors).length ? true : false}
                  >
                    Submit
                  </ThemeButton>
                </Row>
              </Form>
            </div>
          </>)}
      </div>

      <LoadScreen visible={loading} />

      <Modal isOpen={modal} toggle={toggleModal} className="contact-modal">
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <ModalBody className="body">
          <img src={CorneliaFace} width="200" height="auto" alt="Cornelia" />
          {console.log(response.status)}
          <h2>{response.status !== 200 ? "Oops!" : "Thank you!"}</h2>
          <p>
            {response.status !== 200
              ? "Something went wrong."
              : "Your story has been submitted."}
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SubmitStory;
