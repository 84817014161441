import { useState, React } from 'react'
import DrEgg from '../../images/characters/DrEggGlow.png';
import Back from '../../images/btn-Back.png';
import EggDrone from '../../images/characters/EggDrone.png';
import EggDroneFlipped from '../../images/characters/EggDrone-Flipped.png';
import Popup from '../../components/CharacterPopup.js';
import Blackboard from '../../images/Blackboard.png';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../../components/orientation.js';

export const DrEggScreen = () => {
  const [modalState, setModalState] = useState(false);
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider><Helmet>
        <title>Dr Egg</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={Blackboard} alt="Blackboard" />
          <img className="Individual-Character-Styling-Scientist" src={DrEgg} alt="Dr Egg" />
          <Link to='/Characters'><img className="Back-Button characters" src={Back} alt="Back" /></Link>

          <div className='wc-blackboard-container'>
            <h1 className="Character-Type"> The Scientists </h1>
            <hr className="hr-line"></hr>
            <h4 className="individualCharacter DrEgg-Name display-4 pt-3 pb-4"> Dr Eric Egg: THE INGENIOUS INVENTOR </h4>
            <Row>
              <Col md={6} xs={4} className='mx-auto'>
                <div className="Character-Description pt-3">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>🎭 &ensp; Characteristics</Accordion.Header>
                      <Accordion.Body>
                        Dr Egg creates small inventions
                        to cure diseases, as well as inventing biological
                        spying devices to track activities in Professor Mole’s
                        totalitarian empire. He is the twin brother of Dr
                        Moon. Dr Egg acts as Professor Mole’s nemesis.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>😇 &ensp; Virtues</Accordion.Header>
                      <Accordion.Body>
                        Altruistic. Inventive.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>🛠️ &ensp; Tools</Accordion.Header>
                      <Accordion.Body>
                        Flying Egg Spy.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🗺️ &ensp; World</Accordion.Header>
                      <Accordion.Body>
                        Egg Laboratories.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>

          <img className="Character-Pet EggDroneHighlight" src={EggDrone} onClick={() => setModalState(true)} alt="Egg Drone" />

          <Popup trigger={modalState} setTrigger={setModalState}>
            <div className='Companion-Text'>
              <h4 className="EggDrone-Name display-5 mt-4 mb-4">  Flying Egg Spy  </h4>
              <p className='lead text-center text-dark'>This is Dr Egg's companion!</p>
              <img className="Character-EggDrone-Popup" src={EggDroneFlipped} alt="Mouse" />

              <div className="Companion-Description-EggDrone shadow-md mx-auto p-3">
                <p>
                  <b>GOAL</b> &ensp;➡️&ensp; Help Dr Egg find clues.
                </p>
                <p>
                  <b>SPECIAL POWER</b> &ensp;➡️&ensp; Gathers data and clues through roving eye-camera for Dr Egg.
                </p>
              </div>
            </div>
          </Popup>
        </>)}
    </div>
  )
}

export default DrEggScreen;