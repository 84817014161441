import EggLab from '../../assets/images/EggLab.png';
import DrEgg from '../../assets/images/DrEgg.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import Back from "../../images/btn-Back.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';
import React, { useState } from 'react';

const LabWorldSingle = () => {
	const [modalState2, setModalState2] = useState(true);
	const isPortrait = useOrientation();

	return (
		<div className="Characters-Background-Container">
			<HelmetProvider>
				<Helmet>
					<title>Dr Egg's Lab</title>
				</Helmet>
			</HelmetProvider>
			{isPortrait ? (
				<Popup trigger={modalState2} setTrigger={setModalState2}>
					<p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
				</Popup>
			) : (
				<>
					<img
						className="Blackboard"
						src={EggLab}
						alt="Dr Egg's Lab"
					/>
					<img
						className="Individual-Character-Styling"
						style={{ left: "70%", bottom: "0%" }}
						src={DrEgg}
						alt="Dr Egg"
					/>

					<Link to="/Worlds">
						<img className="Back-Button characters" src={Back} alt="Back" />
					</Link>

					<div className="wc-blackboard-container">
						<h1
							className="Character-Type"
							style={{
								textShadow:
									"0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1",
							}}
						>
							{" "}
							DR EGG'S LAB{" "}
						</h1>

						<Row>
							<Col md={6} xs={4} className="mx-auto">
								<div className="Character-Description pt-2">
									<Accordion>
										<Accordion.Item eventKey="0">
											<Accordion.Header>📖 &ensp; Story</Accordion.Header>
											<Accordion.Body>
												Dr Eggs Interactive Laboratory is a place of marvellous miracles. Next to the monstrous SEEDCORP industrial complex, in this tiny laboratory, Dr Egg sits tinkering away on small, often therapeutic biological inventions, such as the handy square tomato and the famous glow-in-the-dark fishes bottom. Dr Egg is positioned to act like “a thorn on the side of SEEDCORP”, and the three kid-heroes are keen to have his help. He has created a spying, flying hybrid tomato to track the inventions Dr Mole has stolen from him.
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>🏠 &ensp; Inhabitants</Accordion.Header>
											<Accordion.Body>Dr Egg, Dr Moon, The Snake, Tank Child.</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>⚠️ &ensp; Dangers</Accordion.Header>
											<Accordion.Body>Bio-goo and Biohazardous waste.</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>🌆 &ensp; Landscape</Accordion.Header>
											<Accordion.Body>Creation Tanks, Petri Dishes, Test-tubes, Bio-goo, Tank Child, The Famous Square tomato plant, Glow In The Dark Creatures (including Fish and Mouse), Experiment Record Books.</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</Col>
						</Row>
					</div>
				</>)}
		</div>
	);
};

export default LabWorldSingle;