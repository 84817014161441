import React, { useState, useRef } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";
import Back from "../images/btn-Back.png";
import Blackboard from "../images/Blackboard.png";
import { useNavigate } from "react-router-dom";
import "../assets/css/modal.css";
import "../assets/css/spinner.css";
import LoadScreen from "../components/LoadScreen";
import styled from "styled-components";
import ThemeButton from "../components/Button";
import CorneliaFace from "../assets/images/CorneliaFace.png";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "../axios";
import "bootstrap/dist/css/bootstrap.min.css";
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

const ContactTitle = styled.h1`
  color: #fff;
  font-family: "polaris", sans-serif;
  font-size: 4.5vh;
  text-align: center;
  font-weight: 700;
  word-spacing: 0.25em;
  padding: 2px;
  margin-top: 1em;
`;

export const ContactForm = ({ sendForm }) => {
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState({
    status: 0,
    msg: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const toggleModal = () => setModal(!modal);

  const validateForm = (field, form) => {
    const errors = {};
    if (!form.name && field === "name") {
      errors.name = "invalid";
    }

    if (field === "email") {
      if (!form.email) {
        errors.email = "invalid";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)
      ) {
        errors.email = "invalid";
      }
    }

    if (!form.message && field === "message") {
      errors.message = "invalid";
    }
    setErrors(errors);

    return errors;
  };

  const handleFieldChange = (e, name) => {
    const newValue = {
      ...form,
      [name]: e.target.value,
    };
    setForm(newValue);

    validateForm(name, newValue);
  };

  const [error, setError] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    fetch("https://formcarry.com/s/q9U8fjr-nq", {
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name: form.name, email: form.email, message: form.message })
    })
      .then(response => response.json())
      .then(response => {
        if (response.code === 200) {
          alert("We received your submission, thank you!");
        }
        else if (response.code === 422) {
          // Field validation failed
          setError(response.message)
        }
        else {
          // other error from formcarry
          setError(response.message)
        }
      })
      .catch(error => {
        // request related error.
        setError(error.message ? error.message : error);
      });
  }
  return (
    <>
      <div className="Contact-Background-Container">
        <HelmetProvider>
          <Helmet>
            <title>Contact Form</title>
          </Helmet>
        </HelmetProvider>
        {isPortrait ? (
          <Popup trigger={modalState2} setTrigger={setModalState2}>
            <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
          </Popup>
        ) : (
          <>
            <img className="Blackboard" src={Blackboard} alt="Blackboard" />
            <img
              className="Back-Button characters"
              src={Back}
              alt="Back"
              onClick={() => navigate(-1)}
            />

            <div className="Contact-Container">
              {/* <FixedWrapper> */}
              <ContactTitle>Contact Form</ContactTitle>
              <Form className="Contact-Form" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} style={{ paddingLeft: "0px" }}>
                    <div className="Contact-FormGroup">
                      <FormGroup floating>
                        <Input
                          id="fname"
                          name="Full Nname"
                          className="setFormBg"
                          placeholder="Full Name"
                          style={{ fontFamily: "museo sans" }}
                          invalid={errors.name === "invalid"}
                          required
                          value={form["name"]}
                          onChange={(e) => handleFieldChange(e, "name")}
                        />
                        <FormFeedback tooltip>Please enter your name.</FormFeedback>
                        <Label for="fname" style={{ fontFamily: "museo sans" }}>
                          Full Name
                        </Label>
                      </FormGroup>{" "}
                    </div>
                  </Col>
                  <Col md={6} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <div className="Contact-FormGroup">
                      <FormGroup floating>
                        <Input
                          type="email"
                          name="email"
                          id="Email"
                          className="setFormBg"
                          placeholder="Parents Email Address (optional)"
                          style={{ fontFamily: "museo sans" }}
                          invalid={errors.email === "invalid"}
                          required
                          value={form["email"]}
                          onChange={(e) => handleFieldChange(e, "email")}
                        />
                        <FormFeedback tooltip>
                          Email incorrect or missing.
                        </FormFeedback>
                        <Label for="Email" style={{ fontFamily: "museo sans" }}>
                          Email
                        </Label>
                      </FormGroup>{" "}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="Contact-FormGroup">
                    <FormGroup>
                      <div className="Contact-Label">
                        <Label
                          className="MLabel"
                          style={{ fontFamily: "museo sans" }}
                        >
                          Type your query below.
                        </Label>
                      </div>
                      <div className="input-wrapper">
                        <Input
                          className="Contact-MessageBox setFormBg"
                          type="textarea"
                          name="text"
                          id="message"

                          style={{ fontFamily: "museo sans", background: "url(\"../assets/images/paper_texture_04.jpg\") !important" }}
                          invalid={errors.message === "invalid"}
                          required
                          value={form["message"]}
                          onChange={(e) => handleFieldChange(e, "message")}
                        />
                        <FormFeedback tooltip>
                          Story field empty or over word limit.
                        </FormFeedback>
                      </div>
                    </FormGroup>
                  </div>
                </Row>
                {/* <Button
							className='Contact-Button'
							disabled={
								Object.keys(errors).length ? true : false
							}>
							<span className='text'> SUBMIT </span>
						</Button> */}
                <Row>
                  {/*
              TODO: reenable recaptcha
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  transform: "scale(0.77)",
                }}
              />*/}
                </Row>
                <Row>
                  <Col
                    md={{
                      offset: 3,
                      size: 8,
                    }}
                    style={{ paddingLeft: "0px" }}
                  >
                    <FormGroup check inline>
                      <Input
                        style={{ backgroundImage: "none !important" }}
                        type="checkbox"
                      />
                      <Label
                        style={{ fontFamily: "museo sans", color: "#ffffff" }}
                        check
                      >
                        Would you like to subscribe to our newsletter?
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{
                      offset: 3,
                      size: 8,
                    }}
                    style={{ paddingLeft: "0px" }}
                  >
                    <FormGroup check inline>
                      <Input
                        style={{ backgroundImage: "none !important" }}
                        type="checkbox"
                        required
                      />
                      <Label
                        style={{ fontFamily: "museo sans", color: "#ffffff" }}
                        check
                      >
                        My parents have read and agreed to the{" "}
                        <a
                          href="https://dreggadventures.com/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: '#F78F96' }}
                        >
                          Privacy Policy
                        </a>.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <ThemeButton
                    fontSize={5}
                    bottom={0}
                    disabled={Object.keys(errors).length ? true : false}
                  >
                    Submit
                  </ThemeButton>
                </Row>
              </Form>
              {/* </FixedWrapper> */}
            </div>
          </>)}
      </div>

      <LoadScreen visible={loading} />

      <Modal isOpen={modal} toggle={toggleModal} className="contact-modal">
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <ModalBody className="body">
          <img src={CorneliaFace} width="200" height="auto" alt="Cornelia" />
          <h2>{response.status !== 200 ? "Oops!" : "Thank you!"}</h2>
          <p>
            {response.status !== 200
              ? "Something went wrong."
              : "Your form has been submitted."}
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ContactForm;