import React, { useState } from 'react';
import Vivi from '../../images/characters/ViviGlow.png';
import Back from '../../images/btn-Back.png';
import Blackboard from '../../images/Blackboard.png';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';

export const ViviScreen = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider><Helmet>
        <title>Vivi</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={Blackboard} alt="Blackboard" />
          <img className="Individual-Character-Styling" src={Vivi} alt="Vivi" />
          <Link to='/Characters'><img className="Back-Button characters" src={Back} alt="Back" /></Link>

          <div className='wc-blackboard-container'>
            <h1 className="Character-Type"> The Kid Inventors </h1>
            <hr className="hr-line"></hr>
            <h4 className="individualCharacter Vivi-Name display-4 pt-2 pb-4"> Vivi: THE CURIOUS CAT INVENTOR </h4>
            <Row>
              <Col md={6} xs={4} className='mx-auto'>
                <div className="Character-Description pt-2">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>🎂 &ensp; Age</Accordion.Header>
                      <Accordion.Body>
                        Vivi is 13 years old.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>🎭 &ensp; Characteristics</Accordion.Header>
                      <Accordion.Body>
                        Vivi is a curious inventor and a real risk-taker.
                        She’s resourceful and loves to ‘do-a-lot with not-a-lot’
                        by recycling bits and pieces she finds in slum world. She’s
                        passionate about finding practical solutions to fix problems
                        like her father’s missing ear. She desperately hopes Dr Egg will
                        help her to grow him a new one!
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>😇 &ensp; Virtues</Accordion.Header>
                      <Accordion.Body>
                        Curious. Empathetic. Brave.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🛠️ &ensp; Tools</Accordion.Header>
                      <Accordion.Body>
                        Home-made toolkit with lots of gadgets. Dad’s old motorcycle helmet and goggles.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>⚡️ &ensp; Powers</Accordion.Header>
                      <Accordion.Body>
                        Through the goggles, she can see things that the other inventors can’t.
                        With her fix-it mentality, she can create inventions for any circumstance.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>🗺️ &ensp; World</Accordion.Header>
                      <Accordion.Body>
                        Slum-lanes.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </>)}
    </div>
  )
}

export default ViviScreen;