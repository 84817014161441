import React, { useState } from 'react';
import Snake from '../images/characters/Snake.png';
import Back from '../images/btn-Back.png';
import { Link } from 'react-router-dom';
import ThemeButton from '../components/Button';
import ClosePop from '../images/close-popup.png';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import { Helmet, HelmetProvider } from "react-helmet-async";
import blackboard from '../images/Blackboard.png';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

const Contact = () => {
	const [modalState2, setModalState2] = useState(true);
	const isPortrait = useOrientation();

	return (
		<div className='Contact-Background-Container'>
			<HelmetProvider><Helmet>
				<title>Contact Us</title>
			</Helmet></HelmetProvider>
			{isPortrait ? (
				<Popup trigger={modalState2} setTrigger={setModalState2}>
					<p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
				</Popup>
			) : (
				<>
					<img className="Blackboard" src={blackboard} alt="blackboard" />
					<div className='Contact-Container'>
						<h1 className='wc-heading'> Contact Us </h1>
						<hr className="hr-line"></hr>

						<Card style={{ width: '30rem' }} className='mx-auto my-5' border='dark'>
							<ListGroup variant="flush">
								<ListGroup.Item
									className="d-flex justify-content-between align-items-start"
								>
									<div className="me-auto listText">
										<div className="fw-bold">📧 &ensp;Email</div>
										<a href='mailto:info@dreggadventures.com'>info@dreggadventures.com</a>
									</div>
								</ListGroup.Item>
								<ListGroup.Item variant="success"
									className="d-flex justify-content-between align-items-start"
								>
									<div className="me-auto listText">
										<div className="fw-bold text-start">📍 &ensp;Address</div>
										Room 156, 10 Hadenfeld Avenue, Macquarie University, Sydney, NSW, 2109, Australia
									</div>
								</ListGroup.Item>
								<ListGroup.Item
									className="d-flex justify-content-between align-items-start"
								>
									<div className="me-auto listText">
										<div className="fw-bold">💻 &ensp;Website</div>
										<a href='https://dreggadventures.com'> www.dreggadventures.com </a>
									</div>
								</ListGroup.Item>
							</ListGroup>
						</Card>

						<Link to='/ContactForm'>
							<Row sm={1} xxl={2}>
								<ThemeButton fontSize={6} bottom={20}> Get In Touch! </ThemeButton>
							</Row>
						</Link>
					</div>

					<Link to='/Map'>
						<img
							className='Back-Button'
							src={Back}
							alt='Back'
						/>
					</Link>

					{/* QnA/CHATBOX STARTS HERE. Note to the person who wants to fix this bizarre implementation: try the Offcanvas React-Bootstrap Component instead of this HTML nonsense left behind by the 2021 team. Sincerely, the 2023 team. */}
					<label htmlFor='chat'>
						<div className='StoreSnake' />
					</label>
					<input className='InputStyle' type='checkbox' id='chat' />
					<div className='ChatBoxContainer'>
						<label htmlFor='chat'>
							<img className='chatClose' src={ClosePop} alt='Close' />
						</label>
						<div className='chatHBox'>
							<img className='chatbotImg' src={Snake} alt='Snake' />
						</div>
						<h4 className='chatQBox mt-3'>What do you need help with?</h4>
						<div className='chatABox'>
							<input className='InputStyle' type='checkbox' id='Q1' />
							<div className='chatCont' id='QCont'>
								<label htmlFor='Q1'>
									<div className='chatBoxQ1'>
										<p>What am I supposed to do on this page? 🤨</p>
									</div>
								</label>
							</div>
							<div className='chatCont' id='A1'>
								<div className='chatBoxA1'>
									<p>What am I supposed to do on this page? 🤨</p>
								</div>
								<div className='chatATxt mt-2'>
									<p> ↪ Human, human, what else are you supposed to do on
										this page? Do YOU have any questions? Find any
										errors? Well do the deed, human, and contact Dr
										Egg Adventures using the contact form! Sssss...
										I’ll see you on the other side.</p>
								</div>
								<label htmlFor='Q1'>
									<div className='chatBackBtn' />
								</label>
							</div>
						</div>
					</div>
				</>)}
		</div>
	);
};

export default Contact;