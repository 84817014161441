import { useState, React } from 'react';
import { Link } from 'react-router-dom';
import fact1 from '../images/fact-img.png';
import fact2 from '../images/fact-img2.png';
import Popup from '../components/SnakePopup.js';
import ardash from '../images/ardash-thinking.png';
import ClosePop from "../images/close-popup.png";
import Back from '../images/btn-Back.png';
import Snake from '../images/characters/Snake.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useOrientation from '../components/orientation.js';


const CrazyFacts = () => {

  const [modalState, setModalState] = useState(true);
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="OnlineStore-Background-Container">

      <HelmetProvider><Helmet>
        <title>Crazy Facts</title>
      </Helmet></HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <div className="OnlineStore-Container">
            <input className="InputStyle" type="checkbox" id="c1" />
            <input className="InputStyle" type="checkbox" id="c2" />

            <div id="bookCover">
              <div className="imgPageCoverContainer">
                <div className="bookTxt2 py-4 px-5">
                  <h3 className="book-heading">Science Breakthrough</h3>
                  <p className="book-text">A young girl called Mary Anning was a fossil collecter and a paleontologist in the 1800's. Don't you know what that means?
                    I didn't either, but apparently it is someone who studies the fossils of the past. At the age of 12, she discovered the first
                    Temnodontosaurus platyodon skeleton. Apparently, it is some kind of ichthyosaur from Jurassic times! It looks kind of like a
                    dolphin, don't you think?
                  </p>
                  <img className="fact" src={fact1} alt="Temnodontosaurus Skeleton" />
                </div>
              </div>
            </div>
            <div className="pageContainer">
              <div className="page" id="p1">
                <div className="backPageContainer">
                  <div className="backPage">
                    <div className="bookTxt2 py-4 px-5">
                      <p className="book-text"> More facts to come!
                      </p>
                    </div>
                  </div>
                  <label htmlFor="c1"><div className="backBtn" /></label>
                </div>
                <div className="frontPageContainer">
                  <div className="frontPage">
                    <div className="bookTxt py-4 px-5">
                      <p className="book-text"> That wasn't her only discovery though! When she turned 24, she discovered the first Plesiosaurus skeleton.
                        I like to think of them as big, angry turtles, with big teeth. How cool!
                      </p>
                      <img className="fact" src={fact2} alt="Plesiosaurus Skeleton" />
                      <p className="book-text pt-3"> Unfortunately nobody recognised her much in her day, but now two species of fish are named after Mary!
                      </p>
                    </div>
                  </div>
                  <label htmlFor="c1"><div className="nextBtn" /></label>
                </div>
              </div>
            </div>
          </div>

          <Link to='/Lab'>
            <img
              className='Back-Button'
              src={Back}
              alt='Back'
            />
          </Link>

          <Popup trigger={modalState} setTrigger={setModalState}>
            <div className="popup-background">
              <img className="ardash-popup" src={ardash} alt="Ardash" />
              <p className="Snake-text">'I've been researching some scientists from your world, and I thought I'd share what I found with you guys.'
              </p>
            </div>
          </Popup>

          {/* QnA/CHATBOX STARTS HERE. Note to the person who wants to fix this bizarre implementation: try the Offcanvas React-Bootstrap Component instead of this HTML nonsense left behind by the 2021 team. Sincerely, the 2023 team. */}
          <label htmlFor="chat">
            <div className="StoreSnake" />
          </label>
          <input className="InputStyle" type="checkbox" id="chat" />
          <div className="ChatBoxContainer">
            <label htmlFor="chat">
              <img className="chatClose" src={ClosePop} alt="Close" />
            </label>
            <div className="chatHBox">
              <img className="chatbotImg" src={Snake} alt="Snake" />
            </div>
            <h4 className="chatQBox mt-3"> What do you need help with? </h4>
            <div className="chatABox">
              <input className="InputStyle" type="checkbox" id="Q1" />
              <div className="chatCont" id="QCont">
                <label htmlFor="Q1">
                  <div className='chatBoxQ1'>
                    <p>Where am I? Why is there a book on the screen?</p>
                  </div>
                </label>
              </div>
              <div className="chatCont" id="A1">
                <div className='chatBoxA1'>
                  <p>Where am I? Why is there a book on the screen?</p>
                </div>
                <div className="chatATxt mt-4">
                  <p>↪ HUMAN! You are currently on the CRAZY SCIENCE FACTS PAGE! Sssss... Welcome, human! Click on the arrows on the bottom of the page to discover more FACTS!</p>
                </div>
                <label htmlFor="Q1">
                  <div className="chatBackBtn" />
                </label>
              </div>
            </div>
          </div>
        </>)}
    </div>

  )
}

export default CrazyFacts;