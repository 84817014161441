import FarmWorld from '../../assets/images/FarmWorld.png';
import Cornelia from '../../images/characters/Cornelia.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import Back from "../../images/btn-Back.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';
import React, { useState } from 'react';

const FarmWorldSingle = () => {
	const [modalState2, setModalState2] = useState(true);
	const isPortrait = useOrientation();

	return (
		<div className="Characters-Background-Container">
			<HelmetProvider>
				<Helmet>
					<title>Corn Farmlands</title>
				</Helmet>
			</HelmetProvider>
			{isPortrait ? (
				<Popup trigger={modalState2} setTrigger={setModalState2}>
					<p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
				</Popup>
			) : (
				<>
					<img
						className="Blackboard"
						src={FarmWorld}
						alt="Corn Farmlands"
					/>
					<img
						className="Individual-Character-Styling"
						style={{ left: "70%", bottom: "0%" }}
						src={Cornelia}
						alt="Cornelia"
					/>

					<Link to="/Worlds">
						<img className="Back-Button characters" src={Back} alt="Back" />
					</Link>

					<div className="wc-blackboard-container">
						<h1
							className="Character-Type"
							style={{
								textShadow:
									"0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1",
							}}
						>
							{" "}
							CORN-FARMLANDS{" "}
						</h1>

						<Row>
							<Col md={6} xs={4} className="mx-auto">
								<div className="Character-Description pt-2">
									<Accordion>
										<Accordion.Item eventKey="0">
											<Accordion.Header>📖 &ensp; Story</Accordion.Header>
											<Accordion.Body>
												In the dusty brown fields under the giant SEEDCORP billboards, sit the empty silos and rusted tractors with their SEEDCORP logos. Only a few farmers, like Tina and Denis, who have unwittingly “sold” the rights to their children for experimentation, are provided with genetically modified seed from SEEDCORP. On those farms, corn grows tall and green and ears of corn act as radio transmitters linked to Dr Mole’s headquarters. As Dr Mole controls all the seed in Farm World, and has locked away all the organic seeds he can, farmers who are unable to afford SEEPCORP’s special genetically modified seed can’t grow anything, and experience severe food shortages. Most farmers survive by procuring what little GM seed they can afford off the “black market”.
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>🏠 &ensp; Inhabitants</Accordion.Header>
											<Accordion.Body>Cornelia and her parents Tina and Dennis.</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>⚠️ &ensp; Dangers</Accordion.Header>
											<Accordion.Body>Brat packs of spoiled children. Dr Mole’s Hybrid Helpers. Starvation.</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>🌆 &ensp; Landscape</Accordion.Header>
											<Accordion.Body>Silos, tractors, fuel tanks, all with the SEEDCORP logo.</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</Col>
						</Row>
					</div>
				</>)}
		</div>
	);
};

export default FarmWorldSingle;