import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import useOrientation from '../components/orientation.js';
import Popup from '../components/Popup';

export const Map = () => {
    const [modalState2, setModalState2] = useState(true);
    const isPortrait = useOrientation();

    return (
        <div className="Map-Container">
            <HelmetProvider><Helmet>
                <title>Map</title>
            </Helmet></HelmetProvider>
            {isPortrait ? (
                <Popup trigger={modalState2} setTrigger={setModalState2}>
                    <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
                </Popup>
            ) : (
                <>
                    <div className="BtnContain">
                        <Link to='/Game'><div className="GamesHeading" /></Link>
                        <Link to="/Contact"><div className="ContactHeading" /></Link>
                        <Link to="/Characters"><div className="CharactersHeading" /></Link>
                        <Link to="/Lab"><div className="LabHeading" /></Link>
                        <Link to="/Worlds"><div className="WorldsHeading" /></Link>
                        <Link to="/Trailer"><div className="TrailerHeading" /></Link>
                        <Link to="/Resources"><div className="ResourcesHeading" /></Link>
                        <Link to="/OnlineStore"><div className="OnlineStoreHeading" /></Link>
                        <Link to="/ChooseYourOwnAdventure"><div className="ChooseYourOwnAdventure" /></Link>
                    </div>
                </>)}
        </div>
    )
}

export default Map;