import SlumWorld from "../../assets/images/SlumWorld.png";
import Vivi from "../../images/characters/Vivi.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import Back from "../../images/btn-Back.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import useOrientation from '../../components/orientation.js';
import Popup from '../../components/Popup';
import React, { useState } from 'react';

const SlumWorldSingle = () => {
  const [modalState2, setModalState2] = useState(true);
  const isPortrait = useOrientation();

  return (
    <div className="Characters-Background-Container">
      <HelmetProvider>
        <Helmet>
          <title>Slum Lanes</title>
        </Helmet>
      </HelmetProvider>
      {isPortrait ? (
        <Popup trigger={modalState2} setTrigger={setModalState2}>
          <p className="Landscape-text"> For a better viewing experience, turn device to Landscape view. </p>
        </Popup>
      ) : (
        <>
          <img className="Blackboard" src={SlumWorld} alt="Slum Lanes" />
          <img
            className="Individual-Character-Styling"
            style={{ left: "70%", bottom: "0%" }}
            src={Vivi}
            alt="Vivi"
          />

          <Link to="/Worlds">
            <img className="Back-Button characters" src={Back} alt="Back" />
          </Link>

          <div className="wc-blackboard-container">
            <h1
              className="Character-Type"
              style={{
                textShadow:
                  "0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1, 0px 0px 4px #83CFB1",
              }}
            >
              {" "}
              SLUM-LANES{" "}
            </h1>

            <Row>
              <Col md={6} xs={4} className="mx-auto">
                <div className="Character-Description pt-2">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>📖 &ensp; Story</Accordion.Header>
                      <Accordion.Body>
                        In sprawled out, windy slum world, starvation and food
                        shortages are every day occurrences. The poor inhabitants
                        suffer as Dr Mole ruthlessly seeks to control the food
                        supplies. \nIn the scary backstreets, roam gangs of “dumped”
                        mutant thugs, the result of Dr Mole’s experiments on
                        children and animals. Anyone can become the thugs’
                        unsuspecting quarry, and be kidnapped for auction at the
                        notorious and scary Mutant and Body Parts Black Market.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>🏠 &ensp; Inhabitants</Accordion.Header>
                      <Accordion.Body>Vivi and her dad Kane.</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>⚠️ &ensp; Dangers</Accordion.Header>
                      <Accordion.Body>
                        Mutant thugs. Dr Mole’s Hybrid Helpers. Kidnapping.
                        Starvation. Mutant and Body Parts Market.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>🌆 &ensp; Landscape</Accordion.Header>
                      <Accordion.Body>
                        Dumped biohazard drums. Rusty pipes. Missing Children
                        Posters. Seedcorp Advertising Billboards.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </>)}
    </div>
  );
};

export default SlumWorldSingle;